import { formatPhoneNumber } from "helpers/phone";
import { Id, PhoneNumber } from "./common";
import { Message } from "./Message";
import _ from 'lodash-es';

// key is id (currently equal to numbers[0])
export interface Contact {
  id: Id; // for now it's numbers[0], in future - when it's implemented in backend - it will be real Id from DB
  photo?: string;
  name?: string;
  numbers: PhoneNumber[];
  currentNumber: string; // don't set it manually! use contactService.setCurrentNumber instead
  lastMessage?: Message;
  lastSmsMessage?: Message;
  lastMmsMessage?: Message;
  lastFaxMessage?: Message;
  hasCalls: boolean;
  hasCallRecordings: boolean;
  hasSms: boolean;
  hasMms: boolean;
  hasVoicemails: boolean;
  hasFax: boolean;
  pinned?: boolean;
}

export const mergeContacts = (contact: Contact, extraInfo: Contact): void => {
  contact.numbers.push(..._.difference(extraInfo.numbers, contact.numbers));
  contact.photo = contact.photo || extraInfo.photo;
  contact.name = contact.name || extraInfo.name;
  contact.lastMessage = (!contact.lastMessage || !extraInfo.lastMessage)
    ? (contact.lastMessage || extraInfo.lastMessage)
    : contact.lastMessage.at > extraInfo.lastMessage.at ? contact.lastMessage : extraInfo.lastMessage;
  contact.lastSmsMessage = !contact.lastSmsMessage || !extraInfo.lastSmsMessage
    ? contact.lastSmsMessage || extraInfo.lastSmsMessage
    : contact.lastSmsMessage.at > extraInfo.lastSmsMessage.at ? contact.lastSmsMessage : extraInfo.lastSmsMessage;
  contact.lastMmsMessage = !contact.lastMmsMessage || !extraInfo.lastMmsMessage
    ? contact.lastMmsMessage || extraInfo.lastMmsMessage
    : contact.lastMmsMessage.at > extraInfo.lastMmsMessage.at ? contact.lastMmsMessage : extraInfo.lastMmsMessage;
  contact.lastFaxMessage = !contact.lastFaxMessage || !extraInfo.lastFaxMessage
    ? contact.lastFaxMessage || extraInfo.lastFaxMessage
    : contact.lastFaxMessage.at > extraInfo.lastFaxMessage.at ? contact.lastFaxMessage : extraInfo.lastFaxMessage;
  contact.hasCalls = contact.hasCalls || extraInfo.hasCalls;
  contact.hasSms = contact.hasSms || extraInfo.hasSms;
  contact.hasMms = contact.hasMms || extraInfo.hasMms;
  contact.hasVoicemails = contact.hasVoicemails || extraInfo.hasVoicemails;
  contact.hasFax = contact.hasFax || extraInfo.hasFax;
}

export function mergeDuplicatedNumbers(contacts: Contact[]) {
  for (let i = contacts.length - 2; i >= 0; i--) {
    const contact = contacts[i];
    for (let j = contacts.length - 1; j > i; j--) {
      const nextContact = contacts[j];
      if (contact.numbers.some(n => nextContact.numbers.includes(n))) {
        mergeContacts(contact, nextContact);
        _.pull(contacts, nextContact);
      }
    }
  }
}

export function getContactDefaultName(contact: Contact | undefined): string {
  return contact?.numbers?.map(number => formatPhoneNumber(number)).join(', ') || '';
}

export function contactHasCustomName(contact: Contact): boolean {
  return Boolean((contact.name || '').trim() && (contact.name || '').trim() !== getContactDefaultName(contact));
}

export function getContactDisplayName(contact: Contact | undefined): string {
  if (!contact) {
    return '';
  }
  return ((contactHasCustomName(contact) && contact.name) || getContactDefaultName(contact) || '').trim();
}

export function getContactDisplayNameWithNumbers(contact: Contact | undefined): string {
  return _.uniq([
    getContactDisplayName(contact),
    getContactDefaultName(contact),
  ]).join(' ');
}
