import { useCallback, useEffect, useState } from "react";

const volume = 0.5;
const dtmfDuration = 100; // ms, should be at least 45
const dtmfTimeout = 15; // ms, should be at least 10

const digits = [
  '123A'.split(''),
  '456B'.split(''),
  '789C'.split(''),
  '*0#D'.split(''),
];

const isValidDigit = (digit: string): boolean => digit.length === 1 && digits.some(row => row.includes(digit));

// helper function to get frequency for DTMF digit
const getDtmfFrequencies = (digit: string): [number, number] => {
  if (!isValidDigit(digit)) {
    return [0, 0];
  }
  const digitRow = digits.findIndex(row => row.includes(digit));
  const digitColumn = digits[digitRow].indexOf(digit);
  const lowFrequency = [697, 770, 852, 941][digitRow];
  const highFrequency = [1209, 1336, 1477, 1633][digitColumn];
  return [lowFrequency, highFrequency];
}

const getNewOscillatorNode = (audioContext: AudioContext): [OscillatorNode, () => void] => { // returns disconnectFunc
  const gainNode = audioContext.createGain();
  gainNode.gain.value = volume;

  const oscillatorNode = audioContext.createOscillator();
  oscillatorNode.type = 'sine';
  oscillatorNode.connect(gainNode);
  gainNode.connect(audioContext.destination);
  const disconnectFunc = () => {
    gainNode.disconnect(audioContext.destination);
    oscillatorNode.disconnect(gainNode);
  };
  return [oscillatorNode, disconnectFunc];

}

let audioContext: AudioContext;

const playDtmfAsync = async (digit: string) => {
  audioContext = audioContext || new window.AudioContext();
  const [lowToneOscillatorNode, disconnectLow] = getNewOscillatorNode(audioContext);
  const [highToneOscillatorNode, disconnectHigh] = getNewOscillatorNode(audioContext);

  const [lowFrequency, highFrequency] = getDtmfFrequencies(digit);
  if (!lowFrequency || !highFrequency) {
    console.warn(`Digit '${digit}' not supported for DTMF tone`);
    return;
  }
  lowToneOscillatorNode.frequency.value = lowFrequency;
  highToneOscillatorNode.frequency.value = highFrequency;

  lowToneOscillatorNode.start();
  highToneOscillatorNode.start();
  await new Promise(resolve => setTimeout(resolve, dtmfDuration));
  lowToneOscillatorNode.stop()
  highToneOscillatorNode.stop()
  disconnectLow();
  disconnectHigh();
  await new Promise(resolve => setTimeout(resolve, dtmfTimeout));
}

export function usePlayDtmf() {
  const [dtmfQueue, setDtmfQueue] = useState<string[]>([]);
  const [queueUpdated, setQueueUpdated] = useState(1);
  const [prevQueueUpdated, setPrevQueueUpdated] = useState(queueUpdated);
  const [isPlaying, setIsPlaying] = useState(false);

  useEffect(() => {
    if (isPlaying) {
      return;
    }
    if (queueUpdated === prevQueueUpdated) {
      return;
    }
    if (dtmfQueue.length === 0) {
      setPrevQueueUpdated(queueUpdated);
      return;
    }
    setIsPlaying(true);
    playDtmfAsync(dtmfQueue[0]).finally(() => {
      setDtmfQueue(dtmfQueue => dtmfQueue.slice(1));
      setQueueUpdated(queueUpdated => queueUpdated + 1);
      setIsPlaying(false);
      setPrevQueueUpdated(queueUpdated);
    });
  }, [dtmfQueue, isPlaying, queueUpdated, prevQueueUpdated]);

  return useCallback((digit: string) => {
    setDtmfQueue(dtmfQueue => [...dtmfQueue, digit]);
    setQueueUpdated(queueUpdated => queueUpdated + 1);
  }, [setDtmfQueue, setQueueUpdated]);
}