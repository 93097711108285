import { Feature, MyNumber } from "models/MyNumber";
import { ApiService, useApiService } from "./useApiService";
import _ from "lodash-es";
import { urls } from "const/urls";
import { formatPhoneNumber } from "helpers/phone";
import { PhoneNumber } from "models/common";
import { AuthService, useAuthService } from "./useAuthService";
import { useService, Service, UpdateDispatcher } from "./useService";

const ud: UpdateDispatcher = new Set();

export function useMyNumberService(): MyNumberService {
  const api = useApiService();
  const authService = useAuthService();
  return useService(ud, MyNumberService, { api, authService });
}

export class MyNumberService extends Service {
  public static serviceName = "MyNumberService";

  public myNumbers: MyNumber[] = [];
  private _current: PhoneNumber | undefined;
  private onLoginHandler: Promise<void> | undefined;

  private readonly api: ApiService;
  private readonly authService: AuthService;

  constructor({
    api,
    authService,
  }: {
    api: ApiService;
    authService: AuthService;
  }) {
    super({ api, authService });
    this.api = api;
    this.authService = authService;
    this.hasFeature = this.hasFeature.bind(this);
  }

  public async init(): Promise<void> {
    if (this.authService.authenticated) {
      this.onLoginHandler = this.onLogin();
    }
    this.authService.onLoginHandlers.push(() => {
      this.onLoginHandler = this.onLoginHandler || this.onLogin();
    });
    this.authService.onLogoutHandlers.push(() => {
      this.myNumbers = [];
      this._current = undefined;
      this.onLoginHandler = undefined;
    });
    if (this.authService.authenticated) {
      await this.onLoginHandler;
    }
  }

  private async onLogin() {
    const myNumbers = await this.loadMyNumbers();
    if (!this.authService.callRecording) {
      const callNumbers = await this.loadCallNumbers();
      for (const myNumber of callNumbers) {
        const existedIndex = myNumbers.findIndex(
          (n) => n.number === myNumber.number
        );
        if (existedIndex !== -1) {
          myNumbers[existedIndex] = this.merge(
            myNumbers[existedIndex],
            myNumber
          );
        } else {
          myNumbers.push(myNumber);
        }
      }
    }
    this.myNumbers = myNumbers;
    this.update();
    if (this.myNumbers.length > 0 && !this._current) {
      this.setCurrent(this.myNumbers[0].number);
    }
    return;
  }

  public setCurrent(number: PhoneNumber) {
    this._current = number;
    this.update();
  }

  public get current(): MyNumber | undefined {
    if (!this._current) {
      return undefined;
    }
    return this.myNumbers.find((n) => n.number === this._current);
  }

  public hasFeature(feature: Feature) {
    return this.myNumbers.some((n) => n.features?.includes(feature));
  }

  public getMyNumberByNumber(number: string): MyNumber | undefined {
    return this.myNumbers.find((n) => n.number === number);
  }

  private async loadMyNumbers(): Promise<MyNumber[]> {
    interface MyNumbersResponse {
      count: number;
      next?: string;
      results: Array<{
        number: string;
        features: {
          call_forwarding: boolean;
          call_recording: boolean;
          call_proc: boolean;
          cnam_inbound: boolean;
          cnam_outbound: boolean;
          cnam_outbound_val: string;
          cr_injectbeep: boolean;
          conference: boolean;
          queue: boolean;
          e911: boolean;
          sms: boolean;
          mms: boolean;
          fax: boolean;
          voicemail: boolean;
        };
      }>;
    }
    const myNumbers: MyNumber[] = [];
    let url = urls.myNumbers;
    do {
      const response = await this.api.get<MyNumbersResponse>(url);
      const newMyNumbers: MyNumber[] = response.results.map((x) => ({
        number: x.number,
        name: formatPhoneNumber(x.number),
        features: [
          ...(x.features.call_forwarding ? [Feature.callForwarding] : []),
          ...(x.features.call_recording ? [Feature.callRecording] : []),
          ...(x.features.call_proc ? [Feature.callProcedures] : []),
          ...(x.features.sms ? [Feature.sms] : []),
          ...(x.features.mms ? [Feature.mms] : []),
          ...(x.features.voicemail ? [Feature.voicemails] : []),
          ...(x.features.fax ? [Feature.fax] : []),
        ],
        sms: x.features.sms ? { ready: false } : undefined,
        mms: x.features.mms ? { ready: false } : undefined,
        fax: x.features.fax ? { ready: false } : undefined,
      }));
      myNumbers.push(...newMyNumbers);
      url = response.next || "";
    } while (url);
    return myNumbers;
  }

  private async loadCallNumbers(): Promise<MyNumber[]> {
    interface RegistrationsResponse {
      registrations: Array<{
        lineId: number;
        displayName: string; // "4243051070"
        uri: string; // "4243051070@webrtc.apeironsys.com";
        authorizationUser: string; // "4243051070",
        password: string;
      }>;
    }
    const response = await this.api.get<RegistrationsResponse>(
      urls.callNumbers
    );
    return response.registrations.map((x) => ({
      number: x.authorizationUser,
      name: formatPhoneNumber(x.authorizationUser),
      features: [Feature.calls],
      calls: {
        ready: false,
        sipPassword: x.password,
      },
    }));
  }

  private merge(myNumber1: MyNumber, myNumber2: MyNumber): MyNumber {
    if (myNumber1.number !== myNumber2.number) {
      throw new Error(
        `Cannot merge two different my numbers ${myNumber1.number} and ${myNumber2.number}`
      );
    }
    let result: MyNumber = {
      number: myNumber1.number,
      name: myNumber1.name,
      features: _.uniq([...myNumber1.features, ...myNumber2.features]),
    };
    if (myNumber1.calls || myNumber2.calls) {
      result.calls = {
        ready: myNumber1.calls?.ready || myNumber2.calls?.ready || false,
        sipPassword:
          myNumber1.calls?.sipPassword || myNumber2.calls?.sipPassword || "",
        sipAgent: myNumber1.calls?.sipAgent || myNumber2.calls?.sipAgent,
      };
    }
    if (myNumber1.sms || myNumber2.sms) {
      result.sms = {
        ready: myNumber1.sms?.ready || myNumber2.sms?.ready || false,
      };
    }
    if (myNumber1.mms || myNumber2.mms) {
      result.mms = {
        ready: myNumber1.mms?.ready || myNumber2.mms?.ready || false,
      };
    }
    if (myNumber1.fax || myNumber2.fax) {
      result.fax = {
        ready: myNumber1.fax?.ready || myNumber2.fax?.ready || false,
      };
    }
    return result;
  }
}
