import { Feature } from 'models/MyNumber';
import { MyNumberService, useMyNumberService } from './useMyNumberService';
import { Direction, Message, MessageType, messageTypeRequiredFeatures } from 'models/Message';
import { ContactService, useContactService } from './useContactService';
import { Id, PhoneNumber } from 'models/common';
import { useService, Service, UpdateDispatcher } from './useService';
import { AuthService, useAuthService } from './useAuthService';

const ud: UpdateDispatcher = new Set();

export function useDraftService(): DraftService {
  const auth = useAuthService();
  const myNumberService = useMyNumberService();
  const contactService = useContactService();
  return useService(ud, DraftService, { auth, myNumberService, contactService });
}

export class DraftService extends Service {
  public static serviceName = "DraftService";

  private drafts: Record<Id, Message> = {}; // key is contactId

  private readonly auth: AuthService;
  private readonly myNumberService: MyNumberService;
  private readonly contactService: ContactService;

  constructor({ auth, myNumberService, contactService }: { auth: AuthService, myNumberService: MyNumberService, contactService: ContactService }) {
    super({ auth, myNumberService, contactService });
    this.auth = auth;
    this.myNumberService = myNumberService;
    this.contactService = contactService;
  }

  public async init() {
    this.auth.onLogoutHandlers.push(() => {
      this.drafts = {};
    });
  }

  public createDraftMessage(contactId: Id = this.contactService.current?.id || '', newPhoneNumber = '', type: MessageType = MessageType.sms): void {
    const key = contactId || 'new';
    if (this.drafts[key]) {
      return;
    }
    const myNumber = this.myNumberService.current?.features?.includes(Feature.sms)
      ? this.myNumberService.current.number
      : this.myNumberService.myNumbers.find(n => n.features.includes(Feature.sms))?.number;
    if (!myNumber) {
      throw new Error('No numbers found with enabled SMS feature');
    }
    const contact = this.contactService.getContactById(contactId)!
    if (contactId && !contact) {
      throw new Error(`Contact with ID=${contactId} not found`);
    }
    this.drafts[key] = {
      myNumber,
      contactNumber: contact?.currentNumber || newPhoneNumber || '',
      direction: Direction.out,
      type,
      at: new Date().getTime(),
    } as Message;
    this.update();
  }

  setNewDraftContactNumber(contactNumber: PhoneNumber) {
    if (!this.drafts['new'] || this.drafts['new'].contactNumber === contactNumber) {
      return;
    }
    this.drafts['new'].contactNumber = contactNumber;
    this.update();
  }

  public updateDraftType(newType: MessageType, contactId: Id = this.contactService.current?.id || ''): boolean {
    const draft = this.drafts[contactId];
    if (!draft) {
      return false;
    }
    if (draft.type === newType) {
      return true;
    }
    const draftMyNumberInfo = this.myNumberService.myNumbers.find(n => n.number === draft.myNumber);
    const requiredFeatures = messageTypeRequiredFeatures[newType];
    if (requiredFeatures.every(feature => draftMyNumberInfo?.features?.includes(feature))) {
      draft.type = newType;
      this.update();
      return true;
    }
    const myNumber = this.myNumberService.current && requiredFeatures.every(feature => this.myNumberService.current?.features?.includes(feature))
      ? this.myNumberService.current.number
      : this.myNumberService.myNumbers.find(n => requiredFeatures.every(feature => n.features.includes(feature)))?.number;
    if (!myNumber) {
      return false;
    }
    draft.type = newType;
    draft.myNumber = myNumber;
    this.update();
    return true;
  }

  public getDraftMessage(contactId: Id = this.contactService.current?.id || ''): Message | undefined {
    return this.drafts[contactId || 'new'] || undefined;
  }

  public deleteDraftMessage(contactId: Id = this.contactService.current?.id || ''): void {
    delete this.drafts[contactId || 'new'];
  }

}
