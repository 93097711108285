import React from 'react';
import { useDrag } from 'react-dnd'
import { Card } from 'primereact/card';
import { CallProcedure } from 'models/CallProcedure';
import { MyNumberCallProcedure } from 'hooks/services/useCallProceduresService';
import { Id } from 'models/common';

export const CallProcedureCard = ({ callProcedure, setMyNumberCallProcedure }: { callProcedure: CallProcedure; setMyNumberCallProcedure: ({ myNumber, callProcedureId }: { myNumber: string; callProcedureId: Id }) => void }) => {
  const [, drag] = useDrag(() => ({
    type: 'callProcedure',
    item: callProcedure,
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult<MyNumberCallProcedure>();
      if (dropResult?.myNumber) {
        setMyNumberCallProcedure({ myNumber: dropResult.myNumber.number, callProcedureId: item.id! })
      }
    },
  }))

  return (
    <div ref={drag}>
      <Card className="call-procedure-card" >
        <label>{callProcedure.label}</label>
      </Card>
    </div>
  );
}