import Color from "color";

export const setBrandColor = ({
  color,
  textColor,
  background,
}: {
  color: string;
  textColor: string;
  background: string;
}) => {
  const app = ".App-Web-Phone";
  const color1 = Color(color).saturate(0.1).hex();
  const color2 = Color(color1).darken(0.15).hex();
  const color3 = Color(color).desaturate(0.6).lighten(0.5).hex();
  const invertedTextColor = Color(color).isLight() ? "black" : "white";
  const backOddRow = Color(background).darken(0.1).hex();
  const btnSelector =
    ".p-button:not(.p-button-success):not(.p-button-danger):not(.p-button-secondary)";
  const btn = `${app} ${btnSelector}`;
  const css = `
    ${btn},
    .p-dialog-visible ${btnSelector},
    ${app} .p-checkbox .p-checkbox-box.p-highlight
      { background: ${color}; border-color: ${color}; color: ${invertedTextColor}; }
    ${btn}:enabled:active, ${btn}:not(button):not(a):not(.p-disabled):active
      { background: ${color2}; border-color: ${color2} }
    ${btn}:enabled:hover, ${btn}:not(button):not(a):not(.p-disabled):hover
      { background: ${color1}; border-color: ${color1} }
    ${btn}:focus
      { box-shadow: 0 0 0 0.2rem ${color3} }
    ${app} .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus
      { border-color: ${color}; box-shadow: 0 0 0 0.2rem ${color3} }
    ${btn}.p-button-outlined { color: ${color} }
    ${app} .p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link
      { color: ${color}; border-color: ${color} }
    ${app} .p-progressbar .p-progressbar-value
      { background: ${color} }
    ${app} .p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus,
    ${app} .p-inputtext:enabled:focus,
      { box-shadow: inset 0 0 0 0.2rem rgba(${Color(color)
        .rgb()
        .array()
        .toString()}, 0.15) }

    ${app} .button,
    ${app} .csv-reader-input,
    ${app} .contacts-component .contact.selected,
    ${app} .sms-lists-component .contact.selected,
    ${app} .sms-templates-component .template.selected,
      { background: ${color} }

    ${app} .button,
    ${app} .csv-reader-input,
    ${app} .contact-card-component.selected:not(.sms-list-mode),
    ${app} .contact-card-component .unread-count,
    ${app} .dialer-component .btn-call,
    ${btn}.p-button-text:hover,
    .p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight
      { background: ${color}; color: ${invertedTextColor} }

    ${app} .add-new-field-btn .p-button
      { color: ${invertedTextColor} }

    ${app} .main-menu-component .mode-btn.active,
    ${app} .page-tabs-component .mode-btn.active,
    ${app} .dialer-component .src-number a,
    ${app} .home-page .number-features .number.active,
    ${app} .home-page .number-features tr:hover .number,
    ${app} .compose-sms-component a,
    ${app} .compose-sms-campaign-component a,
    ${app} .greetings-component .number-card.active
    ${app} .call-procedures-component .number-card.active
      { color: ${color} }

    ${app},
    ${btn}.p-button-text,
    ${app} .p-splitter,
    ${app} .p-tabview .p-tabview-nav li .p-tabview-nav-link,
    ${app} .p-datatable .p-datatable-thead > tr > th,
    ${app} .p-datatable .p-datatable-tbody > tr,
    ${app} .p-component,
    ${app} .p-inputtext,
    ${app} .p-dropdown,
    ${app} .p-tabview .p-tabview-nav,
    .p-dropdown-panel .p-dropdown-items .p-dropdown-item
      { color: ${textColor}; background: ${background} }

    body,
    .p-dropdown-panel
      { background: ${background} }

    ${app} .p-datatable .p-datatable-tbody > tr:nth-of-type(2n)
      { background: ${backOddRow} }
  `;
  const style = document.createElement("style");
  style.textContent = css;
  document.body.append(style);
};
