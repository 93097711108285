import { useCallback, useRef } from "react";

export function useFocus<T>(): [() => void, React.RefObject<T>] {

  const fieldRef = useRef<T>(null);

  const focus = useCallback(() => {
    const timeoutThread = setTimeout(() => {
      let inputElement = fieldRef.current as HTMLInputElement | HTMLTextAreaElement;
      if (inputElement && inputElement.tagName !== 'INPUT' && inputElement.tagName !== 'TEXTAREA') {
        inputElement = inputElement.querySelector('input, textarea') as HTMLInputElement | HTMLTextAreaElement;
      }
      if (inputElement) {
        inputElement.focus();
        inputElement.selectionStart = inputElement.value.length;
      }
    }, 100);
    return () => {
      clearTimeout(timeoutThread);
    }
  }, [fieldRef]);

  return [focus, fieldRef];
}
