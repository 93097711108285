import dayjs from "dayjs";
import { DateTime } from "models/common";

export const secondsToUnixTime = (seconds: number | string): number => {
  return seconds
    ? Number(seconds) < 1e10
      ? Math.round(Number(seconds) * 1000)
      : Number(seconds)
    : 0;
};

export const getFormattedTimeFromTimestamp = (
  timestamp: DateTime,
  format: string = "MM/DD/YY, HH:mm"
): string => {
  return dayjs(timestamp < 1e12 ? timestamp * 1000 : timestamp).format(format);
};

export const addDays = (numOfDays: number) => dayjs().add(numOfDays, "days");

export const getTextDuration = (duration: number): string => {
  // e.g. "4 sec"
  const totalSeconds = duration / 1000;
  const totalMinutes = totalSeconds / 60;
  const hours = Math.floor(totalMinutes / 60);
  const minutes = Math.floor(totalMinutes % 60);
  const seconds = Math.floor(totalSeconds % 60);
  const result =
    [
      hours >= 1 && `${hours} hr`,
      minutes >= 1 && `${minutes} min`,
      hours < 1 && seconds >= 1 && `${seconds} sec`,
    ]
      .filter(Boolean)
      .slice(0, 2)
      .join(" ") || "0 sec";
  return result;
};

export const getDateOrTimeText = (date: number): string => {
  if (dayjs(date).isSame(dayjs(), "date")) {
    return dayjs(date).format("h:mm A");
  } else if (dayjs().diff(dayjs(date), "date") < 7) {
    return dayjs(date).format("ddd");
  } else {
    return dayjs(date).format("M/D/YYYY");
  }
};

export const msToTime = (duration: number) => {
  let seconds = Math.floor((duration / 1000) % 60),
    minutes = Math.floor((duration / (1000 * 60)) % 60),
    hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

  const hoursAsString = hours < 10 ? "0" + hours : hours;
  const minutesAsString = minutes < 10 ? "0" + minutes : minutes;
  const secondsAsString = seconds < 10 ? "0" + seconds : seconds;

  return hoursAsString + ":" + minutesAsString + ":" + secondsAsString;
};

export const formatTimer = (duration: number): string => {
  const h = Math.floor(duration / 3600000);
  const m = Math.floor(Math.floor(duration / 60000) % 60);
  const mm = h ? `0${m}`.slice(-2) : m;
  const ss = `0${Math.floor(Math.floor(duration / 1000) % 60)}`.slice(-2);
  return h ? `${h}:${mm}:${ss}` : `${mm}:${ss}`;
};
