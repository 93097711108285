import React, { Dispatch, SetStateAction } from "react";
import { InputText } from "primereact/inputtext";
import { formatPhoneNumber, toDigitalNumber } from "helpers/phone";
import { useContactService } from "hooks/services/useContactService";

export const NewContactArea = ({ newContactNumber, setNewContactNumber }:
  { newContactNumber: string, setNewContactNumber: Dispatch<SetStateAction<string>> | ((value: string) => void) }
) => {

  const contactService = useContactService();

  const onNewContactNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const digital = toDigitalNumber(e.target.value);
    const existedContact = contactService.contacts.find(c => c.numbers.includes(digital));
    if (existedContact) {
      contactService.setCurrentNumber({ contact: existedContact, currentNumber: digital });
      contactService.current = existedContact;
      setNewContactNumber('');
      return;
    }
    setNewContactNumber(digital);
  }

  return (
    <div className="new-number-area">
      <h3>New contact</h3>
      <p>Please enter the phone number of existing or new contact, and type the message below.</p>
      <p>If entered number already exists in your contact list, you'll be redirected the contact as soon as you enter the number.</p>
      <InputText
        value={formatPhoneNumber(newContactNumber)}
        onChange={onNewContactNumberChange}
        placeholder="Phone number"
        title="Phone number"
      />
    </div>
  );
}