import React, { useCallback } from 'react';
import { useDrop } from 'react-dnd'
import { Card } from 'primereact/card';
import { TiDelete } from 'react-icons/ti';
import { MyNumberCallProcedure } from 'hooks/services/useCallProceduresService';
import { Id } from 'models/common';

export const MyNumberCallProcedureCard = ({ numberCallProcedure, unsetMyNumberCallProcedure }: { numberCallProcedure: MyNumberCallProcedure, unsetMyNumberCallProcedure: ({ myNumber, callProcedureId }: { myNumber: string, callProcedureId: Id }) => void }) => {
  const [{ canDrop, isOver }, drop] = useDrop(() => ({
    accept: 'callProcedure',
    drop: () => numberCallProcedure,
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  }));

  const active = canDrop && isOver;

  const removeClick = useCallback(() => {
    if (!numberCallProcedure.callProcedure?.id) {
      return;
    }
    unsetMyNumberCallProcedure({
      myNumber: numberCallProcedure.myNumber.number,
      callProcedureId: numberCallProcedure.callProcedure?.id,
    });
  }, [numberCallProcedure, unsetMyNumberCallProcedure]);

  return (
    <div ref={drop}>
      <Card className={`number-card ${active && 'active'}`} title={numberCallProcedure.myNumber.name}>        
        {numberCallProcedure.callProcedure ? <>
          <label>{numberCallProcedure.callProcedure.label}</label>
          <TiDelete className="clickable" onClick={removeClick} />
        </> :
          <div className="drop-zone">Drag call procedure here</div>
        }
      </Card>
    </div>
  );
}