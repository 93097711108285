import React, { useState } from "react";
import { useMyNumberService } from "hooks/services/useMyNumberService";
import { useSmsAutoResponderService } from "hooks/services/useSmsAutoResponderService";
import { useTemplateService } from "hooks/services/useTemplatesService";
import { MyNumber } from "models/MyNumber";
import { Template } from "models/Template";
import { AutoComplete } from "primereact/autocomplete";
import { Card } from "primereact/card";
import { Loader } from "components/Loader/Loader";
import "./AutoResponder.scss";

export const AutoResponder = () => {

  const myNumberService = useMyNumberService();
  const templateService = useTemplateService();
  const smsAutoResponderService = useSmsAutoResponderService();

  const [filteredTemplates, setFilteredTemplates] = useState<Template[]>([
    { id: '', name: '(no auto-response)', body: '' },
    ...templateService.templates,
  ]);

  const searchTemplates = (e: any) => {
    let rx: RegExp | null = null;
    try {
      rx = new RegExp(`(^|\\b)${e.query}`, 'i');
    } catch {
      // ignore bad rx
    }
    setFilteredTemplates([
      { id: '', name: '(no auto-response)', body: '' },
      ...templateService.templates.filter(t => !rx || rx.test(t.name || '')),
    ]);
  }

  const onTemplateChange = (myNumber: MyNumber) => async (e: any) => {
    const template = e.value as Template;
    await smsAutoResponderService.setAutoResponder(myNumber.number, template.body);
    myNumberService.update();
  }

  return (
    <div className="auto-responder-component">
      {smsAutoResponderService.autoRespondersLoading && <Loader />}
      {myNumberService.myNumbers.map(myNumber => {
        const template = smsAutoResponderService.getTemplate(myNumber.number);
        return (
          <Card className={`number-card`} title={myNumber.name} key={myNumber.number}>
            <AutoComplete
              placeholder="Template name"
              value={template}
              field="name"
              suggestions={filteredTemplates}
              completeMethod={searchTemplates}
              onChange={onTemplateChange(myNumber)}
              dropdown
            />
          </Card>
        );
    })}
    </div>
  )

};
