import { Loader } from "components/Loader/Loader";
import { useCallForwardingService } from "hooks/services/useCallForwardingService";
import { useMyNumberService } from "hooks/services/useMyNumberService";
import {
  CallerId,
  CallForwardingDetails,
  ForwardingType,
  forwardingTypeNames,
  forwardingTypes,
} from "models/CallForwarding";
import { MyNumber } from "models/MyNumber";
import { Checkbox } from "primereact/checkbox";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import React, { useCallback, useEffect, useState } from "react";
import "./CallForwarding.sass";
import { Button } from "primereact/button";
import { delay } from "helpers/delay";
import { ToggleButton } from "primereact/togglebutton";
import { Search } from "components/Search/Search";

interface ForwardingItem {
  myNumber: MyNumber;
  callForwarding: CallForwardingDetails;
  editMode?: boolean;
}

export const CallForwarding = () => {
  const myNumberService = useMyNumberService();
  const callForwardingService = useCallForwardingService();

  const [callForwardings, setCallForwardings] = useState<ForwardingItem[]>([]);
  const [toNumbers, setToNumbers] = useState<string[]>([]);

  console.log("CallForwarding", callForwardings);

  const updateToNumbers = useCallback((items: ForwardingItem[]) => {
    setToNumbers(
      items.map((item) =>
        item.callForwarding.numbers.filter(Boolean).join(", ")
      )
    );
  }, []);

  useEffect(() => {
    (async () => {
      const theList = await callForwardingService.listAll();
      await myNumberService.ready;
      const myNumbers = myNumberService.myNumbers.filter((n) => n.calls);
      const newCallForwardings = myNumbers.map((myNumber) => ({
        myNumber,
        callForwarding:
          theList.find((cf) => cf.myNumber === myNumber.number) ||
          callForwardingService.getEmptyCallForwarding(myNumber.number),
      }));
      setCallForwardings(newCallForwardings);
      updateToNumbers(newCallForwardings);
    })();
  }, [
    callForwardingService,
    myNumberService.myNumbers,
    myNumberService.ready,
    updateToNumbers,
  ]);

  const updateItem = (
    item: ForwardingItem,
    update: Partial<ForwardingItem>
  ) => {
    const newItem: ForwardingItem = { ...item, ...update };
    const newCallForwardings = [...callForwardings];
    newCallForwardings[callForwardings.indexOf(item)] = newItem;
    setCallForwardings(newCallForwardings);
    updateToNumbers(newCallForwardings);
    if (newItem.callForwarding.numbers[0]) {
      callForwardingService.updateCallForwarding(newItem.callForwarding);
    }
  };

  const updateCallForwarding = (
    item: ForwardingItem,
    update: Partial<CallForwardingDetails>
  ) => {
    updateItem(item, { callForwarding: { ...item.callForwarding, ...update } });
  };

  const updateToNumber = (index: number, value: string) => {
    const newToNumbers = [...toNumbers];
    newToNumbers[index] = value;
    setToNumbers(newToNumbers);
  };

  const commitItem = async (item: ForwardingItem) => {
    await delay(100); // wait for the input to lose focus
    if (item.callForwarding.numbers[0]) {
      callForwardingService.updateCallForwarding(item.callForwarding);
    }
    updateItem(item, { editMode: false });
  };

  const [searchPattern, setSearchPattern] = useState<string>("");

  const filteredCallForwardings = callForwardings.filter(
    (item) =>
      !searchPattern.trim() ||
      item.myNumber.name.toLowerCase().includes(searchPattern.toLowerCase()) ||
      item.callForwarding.numbers.join("").includes(searchPattern)
  );

  return (
    <div className="call-forwarding-component">
      {callForwardings.length > 0 ? (
        <>
          {callForwardings.length > 10 && (
            <Search
              searchPattern={searchPattern}
              setSearchPattern={setSearchPattern}
            />
          )}
          <DataTable value={filteredCallForwardings}>
            <Column
              header="Active"
              body={(item: ForwardingItem) => (
                <Checkbox
                  checked={item.callForwarding.active}
                  onChange={({ checked }) =>
                    updateCallForwarding(item, { active: checked })
                  }
                ></Checkbox>
              )}
            />
            <Column
              header="Phone Number"
              body={(item: ForwardingItem) => item.myNumber.name}
            />
            <Column
              header="Forwarding Type"
              body={(item: ForwardingItem) =>
                item.editMode ? (
                  <Dropdown
                    value={item.callForwarding.type}
                    options={forwardingTypes}
                    valueTemplate={(type: ForwardingType) =>
                      forwardingTypeNames[type]
                    }
                    itemTemplate={(type: ForwardingType) =>
                      forwardingTypeNames[type]
                    }
                    onChange={(e) =>
                      updateCallForwarding(item, { type: e.value })
                    }
                  />
                ) : (
                  <div>
                    {item.callForwarding.active ||
                    item.callForwarding.numbers[0]
                      ? forwardingTypeNames[item.callForwarding.type]
                      : ""}
                  </div>
                )
              }
            />
            <Column
              header="Forward to"
              body={(item: ForwardingItem, { rowIndex }) =>
                item.editMode ? (
                  <div className="form-group">
                    <label>
                      {item.callForwarding.type === ForwardingType.single
                        ? "Phone number"
                        : "Comma-separated numbers"}
                    </label>
                    <InputText
                      className={toNumbers[rowIndex] ? "" : "p-invalid"}
                      value={toNumbers[rowIndex]}
                      onChange={(e) => updateToNumber(rowIndex, e.target.value)}
                      onBlur={() =>
                        updateCallForwarding(item, {
                          numbers: (toNumbers[rowIndex] || "")
                            .split(",")
                            .map((n) => n.trim())
                            .filter(Boolean),
                        })
                      }
                    />
                    {!(toNumbers[rowIndex] || "").trim() && (
                      <small className="error-message">
                        Phone Number
                        {item.callForwarding.type !== ForwardingType.single
                          ? "s"
                          : ""}{" "}
                        should not be empty
                      </small>
                    )}
                  </div>
                ) : (
                  <div>{toNumbers[rowIndex]}</div>
                )
              }
            />
            <Column
              header="Called ID"
              body={(item: ForwardingItem) => (
                <ToggleButton
                  checked={
                    item.callForwarding.callerId === CallerId.dialedNumber
                  }
                  onChange={(e) =>
                    updateCallForwarding(item, {
                      callerId: e.value
                        ? CallerId.dialedNumber
                        : CallerId.callingNumber,
                    })
                  }
                  offLabel="Calling Number"
                  onLabel="Dialed Number"
                />
              )}
            />

            <Column
              header="Edit"
              align="right"
              body={(item: ForwardingItem) =>
                item.editMode ? (
                  (toNumbers[callForwardings.indexOf(item)] || "").trim() ? (
                    <Button onClick={() => commitItem(item)}>OK</Button>
                  ) : null
                ) : (
                  <Button
                    text
                    raised
                    icon="pi pi-pencil"
                    onClick={() => updateItem(item, { editMode: true })}
                  />
                )
              }
            />
          </DataTable>
        </>
      ) : (
        <Loader />
      )}
    </div>
  );
};
