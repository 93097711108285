export const environment = {
  production: false,
  baseUrl: "/",
  api: {
    baseUrl: "https://api.apeiron.io/v2/",
    authUrl: "https://api.apeiron.io/v2/auth",
    autoLoginUrl: "https://dashboard.apeiron.io/auth/jwt/make_token",
    csrf: {
      cookie: "csrftoken",
      header: "X-CSRFToken",
    },
  },
  firebaseConfig: {
    apiKey: "",
    authDomain: "",
    projectId: "",
    // ... and other Firebase config
  },
  firebaseVapidKey: "",
  storagePrefix: "web-phone-", // for localStorage & sessionStorage keys
  defaultBrand: {
    logoUrl: null,
    title: "Apeiron Communicator – Web",
    callRecordingTitle: "Apeiron Call Recordings",
    callForwardingTitle: "Apeiron Call Forwarding",
    color: "#4164FB",
    background: "#FFF",
    textColor: "#333",
    sip: {
      transport: "wss",
      server: "webrtc.apeironsys.com",
      port: 9998,
      userAgent: "Apeiron Communicator - Web v2.0.0",
    },
    smsWebSocket: "wss://ws.apeiron.io/sms?token={ACCESS_TOKEN}",
  },
  sms: {
    storageLimitDays: 30, // cache only messages for last month
  },
  sounds: {
    answered: `${process.env.PUBLIC_URL}/sounds/phone-pick-up-3.mp3`,
    rejected: `${process.env.PUBLIC_URL}/sounds/phone-pick-up-3.mp3`,
    ringback: `${process.env.PUBLIC_URL}/sounds/phone-pick-up-3.mp3`,
    ringing: {
      file: `${process.env.PUBLIC_URL}/sounds/phone-pick-up-3.mp3`,
      volume: 0.5,
    },
    incomingSms: `${process.env.PUBLIC_URL}/sounds/msg-notification.wav`,
  },
  map: {
    url: "http://localhost:4141/",
    secret: "XbhjxiqgGavJaDPY43er",
  },
};
