import { environment } from "environments";

export const forceReload = () => {
  if(environment.production) {
      const form = document.createElement('form');
      form.method = "POST";
      form.action = window.location.href;
      document.body.appendChild(form);
      form.submit();
  } else {
      window.location.replace(window.location.href);
  }
}