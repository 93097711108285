export enum ForwardingType {
  simring = 'simring',
  hunt = 'hunt',
  single = "singleforward",
}

export enum CallerId {
  callingNumber = 'calling_number',
  dialedNumber = 'dialed_number',
}

export const forwardingTypes: ForwardingType[] = [
  ForwardingType.simring,
  ForwardingType.hunt,
  ForwardingType.single,
]

export const forwardingTypeNames: Record<ForwardingType, string> = {
  [ForwardingType.simring]: 'Simring',
  [ForwardingType.hunt]: 'Hunt',
  [ForwardingType.single]: 'Single Forward',
};

export interface CallForwardingDetails {
  myNumber: string;
  active: boolean;
  numbers: string[];
  type: ForwardingType;
  callerId: CallerId;
}

