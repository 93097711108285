import React from 'react';
import _ from 'lodash-es';
import { formatPhoneNumber } from 'helpers/phone';
import { getFormattedTimeFromTimestamp } from 'helpers/date';
import { Link } from 'react-router-dom';
import { routes } from 'const/routes';
import { MassMessage } from 'models/MassMessage';
import "./ChatCampaignMessage.scss";

export const ChatCampaignMessage = ({ message }: { message: MassMessage }) => {

  return (
    <li className="chat-campaign-message-component">
      <div className="message-content">
        <div className="title">{message.templateName}</div>
        <div className="message-body">
          {_.flatten(message.body.split('\n').map((messageLine, i) => [
            <span key={i}>{messageLine}</span>,
            <br key={`${i}_br`} />,
          ]))}
        </div>
      </div>
      <Link to={routes.home} className="my-number">{message.myNumbers.map(n => formatPhoneNumber(n)).join(', ')}</Link>
      <div className="time">{getFormattedTimeFromTimestamp(message.at)}</div>
    </li>
  );

}