import React, { useEffect, useState } from "react";
import { useAuthService } from "hooks/services/useAuthService";
import { Loader } from "components/Loader/Loader";
import { showToast } from "helpers/toast";
import { Header } from "components/Header/Header";
import "./Login.sass";
import { routes } from "const/routes";

export const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const auth = useAuthService();

  const login = async () => {
    try {
      setLoading(true);
      await auth.login(username, password);
    } catch (e) {
      showToast({
        severity: "error",
        summary: "Login failed",
        detail: "Incorrect username or password",
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    let timer: NodeJS.Timeout | null = null;
    if (auth.authenticated) {
      timer = setTimeout(() => {
        // hack to fix the issue when sometimes on unknown reason it get stuck on Login page after login
        const routePathIndex = window.location.href.lastIndexOf(routes.login);
        if (routePathIndex !== -1) {
          window.location.replace(
            window.location.href.substring(0, routePathIndex) + routes.home
          );
        }
      }, 1000);
    }
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [auth.authenticated]);

  if (auth.authenticated) {
    return <Loader />; // navigationService should navigate at this point
  }

  return (
    <div className="login-page">
      <form>
        <Header />
        <div className="form-content">
          <div className="form-group">
            <label>Username</label>
            <input
              type="text"
              className="textbox"
              name="username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label>Password</label>
            <input
              type="password"
              className="textbox"
              name="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          {loading ? (
            <Loader />
          ) : (
            <button
              className="button"
              onClick={(e) => {
                e.preventDefault();
                login();
              }}
            >
              Login
            </button>
          )}
        </div>
      </form>
    </div>
  );
};
