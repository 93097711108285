import { IdNumeric, PhoneNumber } from "./common";

export enum StopWordMatchType {
  exact = 'exact',
  simple = 'simple',
  regex = 'regex',
  any = 'any',
}

// key is id
export interface AutoResponder {
  id: IdNumeric;
  myNumber: PhoneNumber;
  body: string;
  disabled: boolean;
  stopWordMatchType: StopWordMatchType;
  stopWord: string;
}

export const defaultAutoResponder: AutoResponder = {
  id: 0,
  myNumber: '',
  body: '',
  disabled: false,
  stopWordMatchType: StopWordMatchType.regex,
  stopWord: '.*',
};
