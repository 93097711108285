import { environment } from 'environments';
import _ from 'lodash-es';
import cookies from 'js-cookie';
import { showToast } from 'helpers/toast';
import { forceReload } from 'helpers/url';
import { storageKeys } from 'const/storage-keys';
import { Token, anonymousToken } from 'models/Token';
import { getStorageItem } from 'helpers/storage';
import { useService, Service, UpdateDispatcher } from './useService';


const ud: UpdateDispatcher = new Set();

export function useApiService(): ApiService {
  return useService(ud, ApiService);
}

function getToken(): Token {
  return getStorageItem(storageKeys.auth.token) || anonymousToken;
}

interface FetchParams {
  allowAnonymous?: boolean;
  params?: { [key: string]: any } | URLSearchParams;
  form?: { [key: string]: string | Blob } | FormData;
  ignoreErrors?: boolean | number[];
  returnJson?: boolean; // if true or undefined - returns response.json(), otherwise - response
}

export class ApiService extends Service {
  public static serviceName = "ApiService";

  public errorResponse: Response | undefined;

  async get<T>(path: string, fetchParams: FetchParams = {}): Promise<T> {
    return this.fetch<T>('GET', path, '', fetchParams);
  }

  async post<T>(path: string, payload: string | { [key: string]: any } = {}, fetchParams: FetchParams = {}): Promise<T> {
    return this.fetch<T>('POST', path, payload, fetchParams);
  }

  async put<T>(path: string, payload: string | { [key: string]: any } = {}, fetchParams: FetchParams = {}): Promise<T> {
    return this.fetch<T>('PUT', path, payload, fetchParams);
  }

  async delete<T>(path: string, fetchParams: FetchParams = {}): Promise<T> {
    return this.fetch<T>('DELETE', path, '', fetchParams);
  }

  protected async fetch<T>(method: string, path: string, payload: string | { [key: string]: any } = {}, { allowAnonymous, params, form, ignoreErrors, returnJson }: FetchParams = {}): Promise<T> {
    const token = getToken();
    const isAuthenticated = Boolean(token?.accessToken);
    if (!allowAnonymous && !isAuthenticated) {
      throw new Error('Access denied');
    }

    let url = path;
    if (!url.includes('://')) {
      let baseUrl = String(environment.api.baseUrl);
      if (!baseUrl.endsWith('/') && url && !url.startsWith('/')) {
        baseUrl = `${baseUrl}/`;
      }
      if (url.startsWith('/')) {
        url = url.substr('/'.length);
      }
      url = `${baseUrl}${url}`;
    }
    if (params && Object.keys(params).length > 0) {
      const urlParams = new URLSearchParams();
      for (const [key, value] of Object.entries(params)) {
        urlParams.append(key, value);
      }
      url = `${url}?${urlParams.toString()}`;
    }
    const isForm = Boolean(form);
    const formData: FormData = form instanceof FormData ? form : new FormData();
    if (isForm && typeof form === 'object') {
      for (const [key, value] of Object.entries(form as { [key: string]: string | Blob })) {
        formData.append(key, value);
      }
    }
    const isPayloadJson = payload && typeof payload === 'object' && Object.keys(payload).length > 0;
    const strPayload = isPayloadJson ? JSON.stringify(payload) : (payload || '') as string;
    const contentType = isForm ? 'application/x-www-form-urlencoded' : isPayloadJson ? 'application/json' : '';
    let response;
    try {
      response = await fetch(url, { method,
        headers: _.omitBy({
          Authorization: `Bearer ${token.accessToken}`,
          ...(!isForm ? { 'Content-Type': contentType } : {}),
          [environment.api.csrf.header]: cookies.get(environment.api.csrf.cookie),
        }, _.isNil) as Record<string, string>,
        ...(isForm || strPayload ? { body: isForm ? formData : strPayload } : {}),
      });
      this.errorResponse = undefined;
      if ([401, 403].includes(response.status) && isAuthenticated && url !== environment.api.autoLoginUrl) {
        localStorage.removeItem(storageKeys.auth.token);
        forceReload();
      } else if (!response.ok || (response.status >= 400 && response.status <= 599)) {
        if (ignoreErrors === true || (Array.isArray(ignoreErrors) && ignoreErrors.includes(response.status || 0))) {
          return undefined as unknown as T;
        }
        this.errorResponse = response;
        throw new Error(`${response.status} ${response.statusText || 'Request failed'}`);
      }
    } catch (e) {
      // ignore 
      console.error(e);
      if (ignoreErrors !== true && (!Array.isArray(ignoreErrors) || !ignoreErrors.includes(response?.status || 0))) {
        showToast({
          severity: 'error',
          summary: 'An error occurred',
          detail: 'Unknown error occurred. Please try again later or contact us',
        });
      }
    }
    return (returnJson || returnJson === undefined) ? response?.json() : response;
  }
}
