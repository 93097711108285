import { ApiService } from "hooks/services/useApiService";

export const getFileContent = async (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (e: any) => {
      resolve(e.target.result);
    };
    reader.onerror = (e: any) => {
      const error = e.target.error;
      reject(`Error occured while reading ${file.name}: ${error.message}`);
    };
    reader.readAsBinaryString(file);
  });
};

export const downloadFile = (uri: string) => {
  var link = document.createElement("a");
  // link.download = name;
  link.target = "_blank";
  link.href = uri;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

// can be used to download audio files from api - those api urls cannot be used as <audio> src, because they require Authorization header
export const urlFromApiUrl = async (
  api: ApiService,
  secureUrl: string
): Promise<string> => {
  const response = await api.get<Response>(secureUrl, { returnJson: false });
  const blob = await response.blob();
  return URL.createObjectURL(blob);
};
