import _ from "lodash-es";
import { storageKeys } from "const/storage-keys";
import { ApiService, useApiService } from "./useApiService";
import { getStorageItem, setStorageItem } from "helpers/storage";
import { urls } from "const/urls";
import { Id, PhoneNumber } from "models/common";
import { MediaService, useMediaService } from "./useMediaService";
import { Direction, Message, MessageType } from "models/Message";
import { ContactService, useContactService } from "./useContactService";
import { MyNumberService, useMyNumberService } from "./useMyNumberService";
import { sortByAtDescFunc } from "helpers/collection";
import { AuthService, useAuthService } from "./useAuthService";
import { MediaType } from "models/Media";
import { useService, Service, UpdateDispatcher } from "./useService";
import { Feature } from "models/MyNumber";
import { environment } from "environments";

const ud: UpdateDispatcher = new Set();

export function useCallRecordingService(): CallRecordingService {
  const api = useApiService();
  const auth = useAuthService();
  const myNumberService = useMyNumberService();
  const contactService = useContactService();
  const mediaService = useMediaService();
  return useService(ud, CallRecordingService, {
    api,
    auth,
    myNumberService,
    contactService,
    mediaService,
  });
}

export class CallRecordingService extends Service {
  public static serviceName = "CallRecordingService";

  // init values are set in constructor
  private callRecordings: Message[];
  private nextUrls: string[] | undefined; // initially it's undefined, in the middle - array of next page for each of own phone number, finally it's []
  private totalCount: number | undefined; // total count of call recordings - for all own phone numbers for both incoming and outgoing
  private myCallRecordingNumbers: PhoneNumber[]; // on reload check if numbers are the same, otherwise reset cache and reload all

  // WARNING: don't forget to care about data index integrity!
  private callRecordingById: Record<string, Message>; // init value is set in constructor
  private callRecordingsByContactId: Record<string, Message[]>; // init value is set in constructor
  private isFirstTimeAfterLogin = true;
  private loadMoreMutex: Symbol | undefined;

  private readonly api: ApiService;
  private readonly auth: AuthService;
  private readonly myNumberService: MyNumberService;
  private readonly contactService: ContactService;
  private readonly mediaService: MediaService;

  constructor({
    api,
    auth,
    myNumberService,
    contactService,
    mediaService,
  }: {
    api: ApiService;
    auth: AuthService;
    myNumberService: MyNumberService;
    contactService: ContactService;
    mediaService: MediaService;
  }) {
    super({ api, auth, myNumberService, contactService, mediaService });
    this.api = api;
    this.auth = auth;
    this.myNumberService = myNumberService;
    this.contactService = contactService;
    this.mediaService = mediaService;

    // init values
    this.myCallRecordingNumbers =
      getStorageItem(storageKeys.calls.recordingNumbers) || [];
    this.callRecordings = getStorageItem(storageKeys.calls.recordings) || [];
    this.totalCount = getStorageItem(storageKeys.calls.recordingTotalCount);
    this.nextUrls = getStorageItem(storageKeys.calls.recordingNextUrls);
    this.callRecordingById = _.fromPairs(
      this.callRecordings.filter((m) => m.entityId).map((m) => [m.entityId, m])
    );
    this.callRecordingsByContactId = _.fromPairs(
      _.toPairs(
        _.groupBy(
          this.callRecordings.map((m) => ({
            vm: m,
            contactId:
              contactService.getContactByNumber(m.contactNumber)?.id || "",
          })),
          (x: { vm: Message; contactId: Id }) => x.contactId
        )
      ).map(([contactId, items]: [Id, { vm: Message; contactId: Id }[]]) => [
        contactId,
        items.map((x) => x.vm),
      ])
    );
  }

  public update() {
    setStorageItem(storageKeys.calls.recordings, this.callRecordings);
    setStorageItem(storageKeys.calls.recordingTotalCount, this.totalCount);
    setStorageItem(storageKeys.calls.recordingNextUrls, this.nextUrls);
    setStorageItem(
      storageKeys.calls.recordingNumbers,
      this.myCallRecordingNumbers
    );
    super.update();
  }

  public async init(): Promise<void> {
    this.auth.onLoginHandlers.push(() => {
      this.onDependentServiceUpdate({ serviceName: MyNumberService.name });
    });
    this.auth.onLogoutHandlers.push(() => {
      this.resetState();
      this.isFirstTimeAfterLogin = true;
    });
    if (this.auth.authenticated) {
      await this.onDependentServiceUpdate({
        serviceName: MyNumberService.name,
      });
    }
  }

  public async onDependentServiceUpdate({
    serviceName,
  }: {
    serviceName: string;
  }) {
    console.log("CR onDependentServiceUpdate:", { serviceName });
    switch (serviceName) {
      case MyNumberService.name: {
        const myCallRecordingPhoneNumbers =
          this.myNumberService.myNumbers.filter((n) =>
            n.features.includes(Feature.callRecording)
          );
        console.log("CR on numbers 1:", {
          myCallRecordingPhoneNumbers,
          first: this.isFirstTimeAfterLogin,
        });
        if (myCallRecordingPhoneNumbers.length === 0) {
          return;
        }
        const newMyNumbers = myCallRecordingPhoneNumbers.filter(
          (n) => !this.myCallRecordingNumbers.includes(n.number)
        );
        const sortedNumbers = myCallRecordingPhoneNumbers
          .map((n) => n.number)
          .sort((a, b) => (a > b ? 1 : -1));
        console.log("CR on numbers 2:", {
          newMyNumbers,
          sortedNumbers,
          cachedNumbers: this.myCallRecordingNumbers.join(","),
        });
        if (this.isFirstTimeAfterLogin) {
          if (
            sortedNumbers.join(",") === this.myCallRecordingNumbers.join(",")
          ) {
            console.log("CR on numbers 3 recheck totals");
            await this.loadMore(); // just to re-check totals - if they're still the same, then reuse cached data
          } else {
            console.log(
              "CR on numbers 4 different numbers - reset state and reload all"
            );
            this.resetState(); // phone numbers changed, si disreagrd cached data and reload all
            this.isFirstTimeAfterLogin = false;
            this.myCallRecordingNumbers = sortedNumbers;
            await this.loadMore();
          }
          break;
        }
        if (!newMyNumbers.length) {
          break;
        }
        this.myCallRecordingNumbers = sortedNumbers;
        await this.loadMore();
        break;
      }
    }
  }

  // if you have three own numbers then there is 6 nextUrls (2 per number - inbound and outbound)
  // in that case loadMore will load next page by each of 6 nextUrls
  // when some response doesn't have next page, it's not included into nextUrls anymore, thus nextUrls becomes shorter and shorter and finally is empty - it means all call recordings are loaded
  // when this.isFirstTimeAfterLogin flag is set, it means we should re-check actual totals versus cached totals even if all call recordings are loaded. If they are different, then reset cache and reload all over again
  public async loadMore(): Promise<void> {
    interface CallRecordingsResponse {
      count: number;
      next?: string;
      results: Array<{
        timestamp: string; // WARNING: that's not seconds (as in other endpoints)! instead, it's date.toISOString()
        orig_number: string; // from phone number
        term_number: string; // to phone number
        mime_type: string;
        call_id: string;
        call_length: number; // in seconds
        size: number; // in bytes
      }>;
    }

    const mutex = Symbol();
    this.loadMoreMutex = this.loadMoreMutex || mutex;
    while (this.loadMoreMutex && this.loadMoreMutex !== mutex) {
      await new Promise((resolve) => setTimeout(resolve, 100));
      this.loadMoreMutex = this.loadMoreMutex || mutex;
    }

    const { isFirstTimeAfterLogin } = this;
    this.isFirstTimeAfterLogin = false;

    console.log("CR loadMore:", {
      isFirstTimeAfterLogin,
      numbers: this.myCallRecordingNumbers,
      nextUrls: this.nextUrls,
    });

    const recheckTotals = isFirstTimeAfterLogin && this.nextUrls?.length === 0;

    if (recheckTotals || this.nextUrls === undefined) {
      const baseUrl = urls.callRecordings.replace(
        ":customerNumber",
        this.auth.customerNumber
      );
      this.nextUrls = _.flatten(
        this.myCallRecordingNumbers.map((myNumber) => [
          `${baseUrl}?orig_number=${myNumber}`,
          `${baseUrl}?term_number=${myNumber}`,
        ])
      );
    }

    if (!recheckTotals && !this.hasMore) {
      this.loadMoreMutex = undefined;
      return;
    }

    const responses = await Promise.all(
      this.nextUrls.map((url) => this.api.get<CallRecordingsResponse>(url))
    );
    if (recheckTotals || this.totalCount === undefined) {
      // we should re-check actual totals versus cached totals
      const actualTotalCount = _.sumBy(responses, "count");
      console.log("CR recheck totals:", {
        cached: this.totalCount,
        actual: actualTotalCount,
      });
      if (recheckTotals && actualTotalCount === this.totalCount) {
        this.nextUrls = []; // return back how it was before re-checking
        this.update();
        this.loadMoreMutex = undefined;
        return;
      }
      if (actualTotalCount !== this.totalCount) {
        const { nextUrls } = this;
        this.resetState();
        this.totalCount = actualTotalCount;
        this.nextUrls = nextUrls;
      }
    }
    for (const response of responses) {
      const newCallRecordings: Message[] = response.results
        .filter((x) => !this.callRecordingById[x.call_id.toString()])
        .map((x) => {
          const at = new Date(x.timestamp).getTime();
          const audioUrl = [
            environment.api.baseUrl,
            urls.callRecordingAudioSrc.replace(":callId", x.call_id),
          ].join("");
          const media =
            this.mediaService.getMediaByUrl(audioUrl) ||
            this.mediaService.addNewMedia({
              id: `call-recording-${x.call_id}`,
              url: audioUrl,
              type: MediaType.CallRecording,
              duration: x.call_length,
            });
          const direction: Direction = this.myNumberService.myNumbers.some(
            (mn) => mn.number === x.orig_number
          )
            ? Direction.out
            : Direction.in;
          if (
            direction === Direction.in &&
            this.myNumberService.myNumbers.every(
              (mn) => mn.number !== x.term_number
            )
          ) {
            console.error(
              `Call recording ${x.call_id} from ${x.orig_number} to ${x.term_number} - no customer phone number found`
            );
            return null;
          }
          return {
            myNumber:
              direction === Direction.out ? x.orig_number : x.term_number,
            contactNumber:
              direction === Direction.in ? x.orig_number : x.term_number,
            direction,
            type: MessageType.callRecording,
            entityId: x.call_id,
            at,
            sentAt: at,
            mediaId: media.id,
          } as Message;
        })
        .filter((x) => x) as Message[];
      for (const callRecording of newCallRecordings) {
        this.addNewCallRecording(callRecording);
      }
    }
    this.callRecordings.sort(sortByAtDescFunc);
    for (const contactId of Object.keys(this.callRecordingsByContactId)) {
      this.callRecordingsByContactId[contactId].sort(sortByAtDescFunc);
    }
    this.nextUrls = responses.map((r) => r.next).filter(Boolean) as string[];
    this.update();
    this.loadMoreMutex = undefined;
  }

  addNewCallRecording(callRecording: Message) {
    this.callRecordings.push(callRecording);
    if (callRecording.entityId) {
      this.callRecordingById[callRecording.entityId] = callRecording;
    }
    const contact =
      this.contactService.getContactByNumber(callRecording.contactNumber) ||
      this.contactService.addNewContact(
        callRecording.contactNumber,
        callRecording
      );
    contact.hasCallRecordings = true;
    if (contact?.id) {
      this.callRecordingsByContactId[contact.id] =
        this.callRecordingsByContactId[contact.id] || [];
      this.callRecordingsByContactId[contact.id].push(callRecording);
    }
  }

  public getMessagesByContactId(contactId: Id): Message[] {
    return this.callRecordingsByContactId[contactId] || [];
  }

  get totalMessageCount(): number {
    return this.callRecordings.length;
  }

  get loadedCount(): number {
    return this.callRecordings.length;
  }

  public get hasMore(): boolean {
    return (
      this.myCallRecordingNumbers.length > 0 &&
      (this.nextUrls === undefined || this.nextUrls.length > 0)
    );
  }

  // for users with "call_recording" role only
  public get allCallRecordings(): Message[] {
    if (!this.auth.callRecording) {
      return [];
    }
    return this.callRecordings;
  }

  public getAllMessages(): Message[] {
    return this.callRecordings;
  }

  private resetState() {
    this.callRecordings = [];
    this.totalCount = undefined;
    this.nextUrls = undefined;
    this.callRecordingById = {};
    this.callRecordingsByContactId = {};
    this.update();
  }
}
