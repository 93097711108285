import { Dispatch, SetStateAction } from "react";
import { InputText } from "primereact/inputtext";
import { InputIcon } from "primereact/inputicon";
import "./Search.scss";

export const Search = ({
  searchPattern,
  setSearchPattern,
}: {
  searchPattern: string;
  setSearchPattern: Dispatch<SetStateAction<string>>;
}) => (
  <div className="search-component">
    <div>
      <span className="p-input-icon-left">
        <InputIcon className="pi pi-search"> </InputIcon>
        <InputText
          className="p-inputtext-lg"
          value={searchPattern}
          onChange={(e) => setSearchPattern(e.target.value)}
          placeholder="Search"
        />
      </span>
    </div>
  </div>
);
