import { useEffect, useState } from 'react';

export function useTimer({ enabled = true, startTime = 0 }: { enabled?: boolean, startTime?: number } = {}) {
  const [currentTime, updateCurrentTime] = useState(0);
  useEffect(() => {
    if (!enabled) {
      return;
    }
    updateCurrentTime(startTime);
    const interval = setInterval(() => {
      updateCurrentTime(prevTime => prevTime + 1000);
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [enabled, startTime]);
  return currentTime;
};
