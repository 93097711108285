import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useCallService } from "hooks/services/useCallService";
import { useContactService } from "hooks/services/useContactService";
import { useNavigationService } from "hooks/services/useNavigationService";
import { AppMode } from "models/navigation";
import { useMyNumberService } from "hooks/services/useMyNumberService";
import { Button } from "primereact/button";
import { SpeedDial } from "primereact/speeddial";
import { useTemplateService } from "hooks/services/useTemplatesService";
import { Template } from "models/Template";
import { InputTextarea } from "primereact/inputtextarea";
import { Card } from "primereact/card";
import { useMediaService } from "hooks/services/useMediaService";
import { generateGuid } from "helpers/guid";
import {
  Direction,
  Message,
  MessageType,
  messageTypeRequiredFeatures,
} from "models/Message";
import { Media, MediaType } from "models/Media";
import { BsPaperclip, BsTelephone } from "react-icons/bs";
import { MdOutlineClose, MdSend } from "react-icons/md";
import { IoMdAttach } from "react-icons/io";
import { useDraftService } from "hooks/services/useDraftService";
import { useChatService } from "hooks/services/useChatService";
import { Dropdown } from "primereact/dropdown";
import { Loader } from "components/Loader/Loader";
import { useFocus } from "hooks/useFocus";
import { showToast } from "helpers/toast";
import { MenuItem } from "primereact/menuitem";
import { MdLocationPin } from "react-icons/md";
import "./ComposeSms.scss";
import { useAuthService } from "hooks/services/useAuthService";
import { brandContentPlaceholder } from "models/Account";

export const messageTypesAllowingFileUpload = [
  MessageType.mms,
  MessageType.fax,
];

export const ComposeSms = ({
  newPhoneNumber = "",
}: { newPhoneNumber?: string } = {}) => {
  const { mode } = useNavigationService();
  const { current: contact } = useContactService();
  const callService = useCallService();
  const mediaService = useMediaService();
  const myNumberService = useMyNumberService();
  const templateService = useTemplateService();
  const draftService = useDraftService();
  const chatService = useChatService();
  const authService = useAuthService();

  const [file, setFile] = useState<File | undefined>(undefined);
  const [sending, setSending] = useState(false);
  const [resetingFile, setResetingFile] = useState(false); // to remove and add file input element to reset it

  const speedDialRef = useRef<SpeedDial>(null);

  const draft: Message | undefined = draftService.getDraftMessage(contact?.id);
  const media: Media | undefined =
    (draft?.mediaId && mediaService.getMediaById(draft.mediaId)) || undefined;

  const hasRequiredFeatures = useCallback(
    (messageType: MessageType, myNumber = myNumberService.current): boolean => {
      if (!myNumberService.updates) {
        // this line here is only to use myNumberService.updates - to update possibleMessageTypes on every myNumberService.update
        return false;
      }
      return messageTypeRequiredFeatures[messageType].every((feature) =>
        myNumber?.features.includes(feature)
      );
    },
    [myNumberService]
  );

  const possibleMessageTypes = useMemo(() => {
    if (myNumberService.updates === 0) {
      // this line here is only to use myNumberService.updates - to update possibleMessageTypes on every myNumberService.update
      return [];
    }
    return [
      ...((mode === AppMode.chats || mode === AppMode.sms) &&
      hasRequiredFeatures(MessageType.sms)
        ? [MessageType.sms]
        : []),
      ...((mode === AppMode.chats || mode === AppMode.sms) &&
      hasRequiredFeatures(MessageType.mms)
        ? [MessageType.mms]
        : []),
      ...((mode === AppMode.chats || mode === AppMode.fax) &&
      hasRequiredFeatures(MessageType.fax)
        ? [MessageType.fax]
        : []),
    ];
  }, [myNumberService.updates, mode, hasRequiredFeatures]);

  const [newType, setNewType] = useState<MessageType | undefined>(
    possibleMessageTypes[0]
  );

  const waitForDraft = useCallback(async () => {
    while (!draft) {
      await new Promise((resolve) => setTimeout(resolve, 40));
    }
  }, [draft]);

  useEffect(() => {
    if (draft && newType && draft.type !== newType) {
      draftService.updateDraftType(newType);
    }
  }, [draft, newType, draftService]);

  useEffect(() => {
    if (
      (newType && !possibleMessageTypes.includes(newType)) ||
      (!newType && possibleMessageTypes.length > 0)
    ) {
      setNewType(possibleMessageTypes[0]);
    }
  }, [newType, setNewType, possibleMessageTypes]);

  const hasDraft = Boolean(draft);
  useEffect(() => {
    if (!hasDraft) {
      draftService.createDraftMessage(
        contact?.id,
        newPhoneNumber,
        newType || MessageType.sms
      );
    }
  }, [hasDraft, draftService, contact?.id, newPhoneNumber, newType]);

  useEffect(() => {
    if (newPhoneNumber) {
      draftService.setNewDraftContactNumber(newPhoneNumber);
    }
  }, [newPhoneNumber, draftService]);

  const sendMessage = async () => {
    if (!draft) {
      return;
    }
    setSending(true);
    try {
      while (media && !media.fileContent && !media.file) {
        await new Promise((resolve) => setTimeout(resolve, 100));
      }
      await chatService.sendDraftMessage();
      setFile(undefined);
    } finally {
      setSending(false);
    }
  };

  const call = () => {
    const number = contact?.currentNumber || newPhoneNumber;
    if (number) {
      callService.dial(number);
    }
  };

  const [focus, messageRef] = useFocus<HTMLDivElement>();
  useEffect(() => {
    if (!sending) {
      focus();
    }
  }, [focus, sending]);

  const setMessageText = useCallback(
    async (text: string) => {
      await waitForDraft();
      if (draft!.body === text) {
        return;
      }
      draft!.body = text;
      draftService.update();
    },
    [draft, draftService, waitForDraft]
  );

  const onFileUploadChange = async (event: any) => {
    speedDialRef.current?.hide();
    await waitForDraft();
    if (!contact?.currentNumber) {
      return;
    }
    if (!newType || !messageTypesAllowingFileUpload.includes(newType)) {
      const nextType = possibleMessageTypes.filter((mt) =>
        messageTypesAllowingFileUpload.includes(mt)
      )[0];
      setNewType(nextType);
    }
    const theFile: File = event.target.files[0];
    if (file === theFile) {
      return;
    }
    if (!theFile) {
      resetFile();
      return;
    }
    setFile(theFile);
    draft!.mediaId = mediaService.addNewMedia({
      id: generateGuid(),
      mimeType: theFile.type,
      size: theFile.size,
      fileName: theFile.name,
      type: MediaType.MMS,
    }).id;
    draftService.update();
  };

  const sendLocation = async () => {
    try {
      const position: GeolocationPosition = await new Promise(
        (resolve, reject) => {
          navigator.geolocation.getCurrentPosition(resolve, reject);
        }
      );
      const { latitude, longitude } = position.coords;
      chatService.sendMessage({
        myNumber: myNumberService.current?.number || "",
        contactNumber: contact?.currentNumber || "",
        direction: Direction.out,
        type: MessageType.sms,
        at: new Date().getTime(),
        body: `/maps:${latitude},${longitude}`,
      } as Message);
    } catch (e) {
      showToast({
        severity: "error",
        summary: "Unable to share the current location",
      });
    }
  };

  const formatFileSize = (size: number) => {
    const units = ["b", "kb", "Mb", "Gb", "Tb"];
    for (let unitPower = units.length - 1; unitPower >= 0; unitPower--) {
      if (size >= 1024 ** unitPower) {
        return `${Math.round((size / 1024 ** unitPower) * 10) / 10}${
          units[unitPower]
        }`;
      }
    }
  };

  const resetFile = useCallback(() => {
    if (draft?.mediaId || file) {
      if (draft) {
        draft.mediaId = undefined;
      }
      if (file) {
        setFile(undefined);
      }
      draftService.update();
      setResetingFile(true);
    }
  }, [draft, file, draftService]);

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (resetingFile) {
      timeout = setTimeout(() => {
        setResetingFile(false);
      }, 100);
    }
    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [resetingFile]);

  useEffect(() => {
    if (file && !media) {
      return; // wait a little bit - few milliseconds later media would be created and this effect would be invoked again
    }
    if (
      media &&
      file &&
      !media.downloadingFileContent &&
      !media.fileContent &&
      !media.file
    ) {
      const theFile = file;
      media.file = theFile;
      // media.downloadingFileContent = true;
      draftService.update();
      // TODO: uncommment the following block when bug with sending fileContent will be resolved in mmsService
      /*
      getFileContent(theFile).then(fileContent => {
        if (theFile === file) { // user could change it during the file downloading
          media.fileContent = fileContent;
          media.downloadingFileContent = false;
          draftService.update();
        }
      });
      */
    } else {
      resetFile();
    }
  }, [media, file, resetFile, draftService]);

  // const canUploadFile = messageTypesAllowingFileUpload.some((mt) =>
  //   possibleMessageTypes.includes(mt)
  // );

  const templateOptions = templateService.templates.map((t) => ({
    label: t.name,
    value: t,
  }));

  const myNumbersOptions = newType
    ? myNumberService.myNumbers
        .filter((n) => hasRequiredFeatures(newType, n))
        .map((n) => ({ label: n.name, value: n.number }))
    : [];

  const canHaveTextMessage = newType && newType !== MessageType.fax;

  const shareMenuItems: MenuItem[] = [
    {
      label: "Upload",
      // icon: 'pi pi-upload',
      template: () => (
        <div className="upload">
          <Button
            className="p-button-rounded"
            disabled={!contact?.currentNumber && !newPhoneNumber}
          >
            <BsPaperclip />
          </Button>
          {!resetingFile && (
            <input type="file" name="file" onChange={onFileUploadChange} />
          )}
        </div>
      ),
    },
    ...(contact?.currentNumber && canHaveTextMessage
      ? [
          {
            label: "Location",
            // icon: 'pi pi-map-marker',
            template: () => (
              <Button className="p-button-rounded">
                <MdLocationPin />
              </Button>
            ),
            command: () => sendLocation(),
          },
        ]
      : []),
  ].filter(Boolean);

  const usingBrandingSmsTemplate = Boolean(
    (authService.account.customData.branding.smsTemplate || "").replace(
      brandContentPlaceholder,
      ""
    )
  );

  return (
    <div className="compose-sms-component">
      {sending && <Loader />}
      <div className={`container ${sending ? "invisible" : ""}`}>
        <div className="message-area" ref={messageRef}>
          {file ? (
            <Card className="attachment-card">
              <IoMdAttach />
              <div className="filesize">{formatFileSize(file.size)}</div>
              <div className="filename">{file.name}</div>
              <div className="spacer" />
              <MdOutlineClose className="clickable" onClick={resetFile} />
            </Card>
          ) : null}
          {canHaveTextMessage && (
            <div className="text-message">
              <InputTextarea
                placeholder={`Write a message${
                  usingBrandingSmsTemplate ? "*" : ""
                }`}
                rows={3}
                className="input-body"
                value={draft?.body || ""}
                onChange={(e) => setMessageText(e.target.value)}
                onKeyDown={(e) =>
                  e.key === "Enter" &&
                  !e.shiftKey &&
                  !e.altKey &&
                  e.metaKey &&
                  sendMessage()
                }
                autoResize
              />
            </div>
          )}
          {canHaveTextMessage && usingBrandingSmsTemplate && (
            <div className="branding-sms-note">
              * Branding SMS Template is used for this SMS
            </div>
          )}
        </div>
        <div className="footer">
          <div className="buttons">
            <SpeedDial
              ref={speedDialRef}
              model={shareMenuItems}
              direction="up"
            />
          </div>
          {canHaveTextMessage && (
            <Dropdown
              placeholder="Template"
              options={templateOptions}
              value={null}
              onChange={(e) => setMessageText((e.value as Template)?.body)}
              filter
            />
          )}
          <div className="spacer"></div>
          <Dropdown
            value={newType}
            options={possibleMessageTypes}
            onChange={(e) => setNewType(e.value)}
          />
          <div>
            <span>From </span>
            <Dropdown
              value={myNumberService.current?.number}
              options={myNumbersOptions}
              onChange={(e) => myNumberService.setCurrent(e.value)}
            />
          </div>
          <div className="buttons">
            {mode === AppMode.chats && !draft?.body && !media ? (
              <Button
                className="p-button-rounded p-button-success"
                onClick={call}
                disabled={
                  !myNumberService.current?.calls?.ready ||
                  (!contact?.currentNumber && !newPhoneNumber)
                }
              >
                <BsTelephone
                  title={`Call ${myNumberService.current?.name || ""}`}
                />
              </Button>
            ) : (
              <Button
                className="p-button-rounded"
                onClick={sendMessage}
                disabled={!draft?.body && !media}
                title="Send the message"
              >
                <MdSend />
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
