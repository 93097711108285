import React, { useEffect, useState } from "react";
import { useMyNumberService } from "hooks/services/useMyNumberService";
import { Button } from "primereact/button";
import { useSmsCampaignService } from "hooks/services/useSmsCampaignService";
import { useSmsListService } from "hooks/services/useSmsListService";
import { Coding, SmsCampaign } from "models/MassMessage";
import { Loader } from "components/Loader/Loader";
import { InputTextarea } from "primereact/inputtextarea";
import { AutoComplete } from "primereact/autocomplete";
import { Link } from "react-router-dom";
import { routes } from "const/routes";
import { Template } from "models/Template";
import { useTemplateService } from "hooks/services/useTemplatesService";
import { MdSend } from "react-icons/md";
import { useAuthService } from "hooks/services/useAuthService";
import "./ComposeSmsCampaign.scss";
import { brandContentPlaceholder } from "models/Account";

interface Suggestion {
  name: string;
  campaign?: SmsCampaign;
  template?: Template;
}

export const ComposeSmsCampaign = () => {
  const campaignService = useSmsCampaignService();
  const { currentSmsList } = useSmsListService();
  const myNumberService = useMyNumberService();
  const templateService = useTemplateService();
  const authService = useAuthService();

  const [suggestion, setSuggestion] = useState<Suggestion | undefined>(
    undefined
  );
  const [campaign, setCampaign] = useState<SmsCampaign | undefined>(undefined);
  const [filteredSuggestions, setFilteredSuggestions] = useState<Suggestion[]>(
    []
  );
  const [name, setName] = useState<string>("");
  const [body, setBody] = useState<string>("");

  const usingBrandingSmsTemplate = Boolean(
    !suggestion &&
      (authService.account.customData.branding.smsTemplate || "").replace(
        brandContentPlaceholder,
        ""
      )
  );

  const runCampaign = async () => {
    if (!currentSmsList || !myNumberService.current?.sms?.ready) {
      return;
    }
    let theCampaignId = campaign?.id;
    const theSmsListId = currentSmsList?.id;
    const theFromNumbers = [myNumberService.current.number];
    let content = body;
    if (usingBrandingSmsTemplate) {
      content = (
        authService.account.customData.branding.smsTemplate ||
        brandContentPlaceholder
      ).replaceAll(brandContentPlaceholder, content);
    }
    if (!theCampaignId) {
      theCampaignId = await campaignService.createSmsCampaign({
        name,
        body: content,
      });
    }
    await campaignService.composeNewMessage({
      campaignId: theCampaignId,
      smsListId: theSmsListId,
      fromNumbers: theFromNumbers,
      coding: Coding.ucs2,
    });
  };

  useEffect(() => {
    setName(campaign?.id ? campaign?.name || "" : "");
    setBody(campaign?.body || "");
  }, [campaign, campaignService.campaigns]);

  const searchCampaigns = (e: { query: string }) => {
    setName(e.query);
    // filter by search pattern
    let rx: RegExp | null = null;
    try {
      rx = new RegExp(`(^|\\b)${e.query}`, "i");
    } catch {
      // ignore bad rx
    }
    setFilteredSuggestions([
      { name: "(new one)" },
      ...campaignService.campaigns
        .filter((c) => !rx || rx.test(c.name || ""))
        .map((c) => ({ name: c.name, campaign: c })),
      ...templateService.templates
        .filter((t) => !rx || rx.test(t.name || ""))
        .map((t) => ({ name: t.name, template: t })),
    ]);
  };

  const onSuggestionChange = (suggestion: Suggestion) => {
    setSuggestion(
      suggestion.campaign || suggestion.template ? suggestion : undefined
    );
    setCampaign(suggestion.campaign);
    const template = suggestion.template;
    if (template) {
      setTimeout(() => {
        setName(template.name);
        setBody(template.body);
      }, 50);
    }
  };

  return (
    <>
      {campaignService.smsCampaignsLoading && <Loader />}
      <div className="compose-sms-campaign-component">
        <span className="desc">
          From
          <br />
          <Link
            to={routes.home}
            className={!myNumberService.current?.sms?.ready ? "red" : ""}
          >
            {myNumberService.current?.name}
          </Link>
        </span>
        <div className="auto-flex">
          <AutoComplete
            placeholder="Template name"
            value={suggestion}
            field="name"
            suggestions={filteredSuggestions}
            completeMethod={searchCampaigns}
            onChange={(e) => onSuggestionChange(e.value || undefined)}
            dropdown
          />
          <InputTextarea
            placeholder={`Message text${usingBrandingSmsTemplate ? "*" : ""}`}
            rows={2}
            className="input-body"
            value={body}
            onChange={(e) => setBody(e.target.value)}
            autoResize
          />
          {usingBrandingSmsTemplate && (
            <div className="branding-sms-note">
              * Branding SMS Template is used for these SMS
            </div>
          )}
        </div>
        <Button
          className="p-button-rounded btn-send"
          onClick={runCampaign}
          disabled={!name || !body}
          title="Run campaign"
        >
          <MdSend />
        </Button>
      </div>
    </>
  );
};
