import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import "./BrandManagement.sass";
import { useAuthService } from "hooks/services/useAuthService";
import { ColorPicker } from "primereact/colorpicker";
import { Button } from "primereact/button";
import { Media, MediaType } from "models/Media";
import { useMediaService } from "hooks/services/useMediaService";
import { Loader } from "components/Loader/Loader";
import { useApiService } from "hooks/services/useApiService";
import { environment } from "environments";
import { urlFromApiUrl } from "helpers/file";
import { TabPanel, TabView } from "primereact/tabview";
import { InputTextarea } from "primereact/inputtextarea";
import { brandContentPlaceholder } from "models/Account";

enum TemplateTab {
  SMS = 0,
  RCS = 1,
}

const templateTabNames: Record<TemplateTab, string> = {
  [TemplateTab.SMS]: "SMS",
  [TemplateTab.RCS]: "RCS",
};

const contentHtmlPlaceholder = `<p>${brandContentPlaceholder}</p>`;
const logoPlaceholder = "[LOGO]";
const logoHtmlRx = /<img class="rcs_logo-img" src="([^"]*)">/g;

export const BrandManagement = () => {
  const api = useApiService();
  const authService = useAuthService();
  const mediaService = useMediaService();
  const { branding } = authService.account.customData;

  const [saving, setSaving] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [title, setTitle] = useState("");
  const [color, setColor] = useState("");
  const [logoFile, setLogoFile] = useState<File | undefined>();
  const [logoMedia, setLogoMedia] = useState<Media | null>(null);
  const [logoUrl, setLogoUrl] = useState<string>("");
  const [templateTabIndex, setTemplateTabIndex] = useState(0);
  const [smsTemplate, setSmsTemplate] = useState("");
  const [rcsTemplateHtml, setRcsTemplateHtml] = useState("");

  const [rcsTemplateText, setRcsTemplateText] = useState(""); // is not saved to branding, that's for internal use only
  const [rcsTemplateRawHtml, setRcsTemplateRawHtml] = useState(""); // is not saved to branding, that's for internal use only

  useEffect(() => {
    setTitle(branding.title || "");
    setColor(branding.color || "");
    setLogoMedia(
      branding.logoDocumentId
        ? {
            type: MediaType.RcsLogo,
            id: branding.logoDocumentId || "",
            url: "",
          }
        : null
    );
    setSmsTemplate(branding.smsTemplate || brandContentPlaceholder);
    setRcsTemplateHtml(branding.rcsTemplate || contentHtmlPlaceholder);
    setRcsTemplateText(branding.rcsTemplate || "");
    setRcsTemplateRawHtml(branding.rcsTemplate || contentHtmlPlaceholder);
  }, [branding]);

  useEffect(() => {
    if (logoMedia?.id && !logoMedia.url) {
      (async () => {
        await mediaService.ready;
        await mediaService.loadMedia();
        const media = mediaService.getMediaById(logoMedia.id);
        if (media) {
          setLogoMedia(media);
        } else {
          setLogoMedia(null);
        }
      })();
    }
  }, [logoMedia, mediaService]);

  useEffect(() => {
    if (logoMedia?.url?.startsWith(environment.api.baseUrl)) {
      urlFromApiUrl(api, logoMedia.url).then((url) => setLogoUrl(url));
    } else {
      setLogoUrl(logoMedia?.url || "");
    }
  }, [api, logoMedia?.url]);

  const valid = useMemo(
    () =>
      (smsTemplate || brandContentPlaceholder).includes(
        brandContentPlaceholder
      ) &&
      (rcsTemplateText || brandContentPlaceholder).includes(
        brandContentPlaceholder
      ),
    [smsTemplate, rcsTemplateText]
  );

  const changed = useMemo(
    () =>
      title.trim() !== (branding.title || "") ||
      (color || "") !== (branding.color || "") ||
      ((logoMedia?.id || "") !== (branding.logoDocumentId || "") &&
        !(logoFile && !logoMedia)) || // prevent from proceed when file is selected but not uploaded yet
      (smsTemplate.trim() || brandContentPlaceholder) !==
        (branding.smsTemplate || brandContentPlaceholder) ||
      (rcsTemplateRawHtml.trim().replace(logoHtmlRx, logoPlaceholder) ||
        contentHtmlPlaceholder) !==
        (branding.rcsTemplate || contentHtmlPlaceholder),
    [
      branding.color,
      branding.logoDocumentId,
      branding.rcsTemplate,
      branding.smsTemplate,
      branding.title,
      color,
      logoFile,
      logoMedia,
      rcsTemplateRawHtml,
      smsTemplate,
      title,
    ]
  );

  const saveBranding = async (e: any) => {
    e.preventDefault();
    setSaving(true);
    branding.title = title.trim() || undefined;
    branding.color = color || undefined;
    branding.logoDocumentId = logoMedia?.id || undefined;
    branding.smsTemplate =
      (smsTemplate.trim() || brandContentPlaceholder) !==
      brandContentPlaceholder
        ? smsTemplate.trim()
        : undefined;
    branding.rcsTemplate =
      (rcsTemplateText.trim() || brandContentPlaceholder) !==
      brandContentPlaceholder
        ? rcsTemplateRawHtml.trim().replace(logoHtmlRx, logoPlaceholder)
        : undefined;
    try {
      await authService.saveCustomData();
    } finally {
      setSaving(false);
    }
  };

  const onLogoFileUploadChange = async (
    event: ChangeEvent<HTMLInputElement>
  ): Promise<void> => {
    setLogoMedia(null);
    const file = event.target.files?.[0];
    setLogoFile(file);
    if (!file) {
      return;
    }
    setUploading(true);
    try {
      const media = await mediaService.uploadMedia({
        file,
        type: MediaType.RcsLogo,
      });
      setLogoFile(undefined);
      await mediaService.loadMedia();
      setLogoMedia(mediaService.getMediaById(media?.id || "") || null);
    } finally {
      setTimeout(() => setUploading(false), 100);
    }
  };

  const handleEditorInput = useCallback(
    (e: React.ChangeEvent<HTMLDivElement>) => {
      const editor = e.currentTarget as HTMLDivElement;
      setRcsTemplateText(editor.innerHTML);
      setRcsTemplateRawHtml(editor.innerHTML);
    },
    []
  );

  const handleEditorChange = useCallback(
    (e: React.ChangeEvent<HTMLDivElement>) => {
      const editor = e.currentTarget as HTMLDivElement;
      setRcsTemplateHtml(editor.innerHTML);
      setRcsTemplateRawHtml(editor.innerHTML);
    },
    []
  );

  useEffect(() => {
    let newRcsTemplateHtml = rcsTemplateRawHtml;
    if (newRcsTemplateHtml.match(logoHtmlRx)) {
      newRcsTemplateHtml = newRcsTemplateHtml.replace(
        logoHtmlRx,
        logoPlaceholder
      );
    }
    if (logoUrl && newRcsTemplateHtml.includes(logoPlaceholder)) {
      newRcsTemplateHtml = newRcsTemplateHtml.replace(
        logoPlaceholder,
        `<img class="rcs_logo-img" src="${logoUrl}">`
      );
    }
    if (newRcsTemplateHtml !== rcsTemplateRawHtml) {
      setRcsTemplateHtml(newRcsTemplateHtml);
      setRcsTemplateRawHtml(newRcsTemplateHtml);
    }
  }, [logoUrl, rcsTemplateRawHtml]);

  return (
    <div className="config-page-brand-management-tab">
      <form>
        <div className="form-group">
          <label>Title</label>
          <div>
            <input
              type="text"
              className="textbox"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </div>
        </div>
        <div className="form-group">
          <label>Color</label>
          <ColorPicker
            value={color}
            onChange={(e) => setColor((e.value as string).toUpperCase())}
          />
          <input
            type="text"
            className="textbox color-textbox"
            value={color}
            onChange={(e) => setColor(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label>Logo</label>
          {uploading && <Button>Uploading...</Button>}
          <div className="upload">
            {!uploading && <Button>Choose File{logoFile && " ✅"}</Button>}
            <input
              type="file"
              name="file"
              accept="image/png,image/jpeg"
              onChange={onLogoFileUploadChange}
            />
          </div>
          {logoMedia?.id && !logoUrl ? (
            <Loader />
          ) : (
            logoUrl && (
              <div className="logo">
                <img src={logoUrl} alt="brand logo" />
              </div>
            )
          )}
        </div>
        <div className="form-group">
          <label>Templates</label>
          <div className="auto-flex">
            <TabView
              activeIndex={templateTabIndex}
              onTabChange={({ index }) => setTemplateTabIndex(index)}
            >
              {Object.values(templateTabNames).map((tabName, i) => (
                <TabPanel key={i} header={tabName} />
              ))}
            </TabView>
            <div
              className={`template-panel ${
                templateTabIndex !== TemplateTab.SMS && "hidden"
              }`}
            >
              <InputTextarea
                value={smsTemplate}
                onChange={(e) => setSmsTemplate(e.target.value)}
                rows={5}
                cols={80}
              />
              <div>
                <div className="description">
                  Content of original SMS will be wrapped with the provided
                  template by substituting{" "}
                  <span className="placeholder">{brandContentPlaceholder}</span>{" "}
                  placeholder with the original content.
                </div>
                {smsTemplate &&
                  !smsTemplate.includes(brandContentPlaceholder) && (
                    <div className="warning">
                      Template must contain{" "}
                      <span className="placeholder">
                        {brandContentPlaceholder}
                      </span>{" "}
                      placeholder.
                    </div>
                  )}
              </div>
            </div>
            <div
              className={`template-panel ${
                templateTabIndex !== TemplateTab.RCS && "hidden"
              }`}
            >
              <div
                className="editor"
                contentEditable
                onInput={handleEditorInput}
                onBlur={handleEditorChange}
                dangerouslySetInnerHTML={{ __html: rcsTemplateHtml }}
              />
              <div>
                <div className="description">
                  Content of original RCS will be wrapped with the provided
                  template by substituting{" "}
                  <span className="placeholder">{brandContentPlaceholder}</span>{" "}
                  placeholder with the original content.
                  <br />
                  <br />
                  Logo can be inserted with{" "}
                  <span className="placeholder">{logoPlaceholder}</span>{" "}
                  placeholder.
                </div>
                {rcsTemplateText.trim() &&
                  !rcsTemplateText.includes(brandContentPlaceholder) && (
                    <div className="warning">
                      Template must contain{" "}
                      <span className="placeholder">
                        {brandContentPlaceholder}
                      </span>{" "}
                      placeholder.
                    </div>
                  )}
              </div>
            </div>
          </div>
        </div>
        <button
          className={`button submit-button ${
            (!valid || !changed || saving) && "disabled"
          }`}
          onClick={saveBranding}
          disabled={!valid || !changed || saving}
        >
          Save
        </button>
      </form>
    </div>
  );
};
