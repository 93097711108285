import { DateTime, IdNumeric, PhoneNumber } from "./common";

export interface SmsCampaign {
  id: IdNumeric;
  at: DateTime;
  name: string;
  body: string;
}

export enum Coding {
  ascii = 'ASCII',
  utf8 = 'UTF8',
  ucs2 = 'UCS2',
}

export const codings = [Coding.ucs2, Coding.ascii, Coding.utf8];

export interface MassMessage { // aka Sms Campaign Run
  smsCampaignRunId: number;
  smsCampaignId: number;
  smsListId: number;
  myNumbers: PhoneNumber[];
  status: string;
  coding: Coding;
  templateName: string;
  body: string;
  at: DateTime; // for call it means "ended at"
}
