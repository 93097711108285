import { ApiService, useApiService } from "./useApiService";
import { urls } from "const/urls";
import {
  ForwardingType,
  CallForwardingDetails,
  CallerId,
} from "models/CallForwarding";
import { useService, Service, UpdateDispatcher } from "./useService";
import { MyNumberService, useMyNumberService } from "./useMyNumberService";
import { Feature } from "models/MyNumber";

const ud: UpdateDispatcher = new Set();

export function useCallForwardingService(): CallForwardingService {
  const api = useApiService();
  const myNumberService = useMyNumberService();
  return useService(ud, CallForwardingService, { api, myNumberService });
}

export class CallForwardingService extends Service {
  public static serviceName = "CallForwardingService";

  private readonly api: ApiService;
  private readonly myNumberService: MyNumberService;

  constructor({
    api,
    myNumberService,
  }: {
    api: ApiService;
    myNumberService: MyNumberService;
  }) {
    super({ api });
    this.api = api;
    this.myNumberService = myNumberService;
  }

  public async listAll(): Promise<CallForwardingDetails[]> {
    interface CallForwardingResponseItem {
      from_number: string;
      number1: string;
      number2: string;
      number3: string;
      number4: string;
      number5: string;
      forwarding_type: ForwardingType;
      caller_id: string;
      active: boolean;
    }
    await this.ready;
    const myCallForwardingNumbers = this.myNumberService.myNumbers
      .filter((myNumber) => myNumber.features.includes(Feature.callForwarding))
      .map((myNumber) => myNumber.number);
    const response = await this.api.get<CallForwardingResponseItem[]>(
      urls.callForwardings
    );
    return response
      .filter((item) => myCallForwardingNumbers.includes(item.from_number))
      .map(
        (item) =>
          ({
            myNumber: item.from_number,
            active: item.active,
            numbers: [
              item.number1,
              item.number2,
              item.number3,
              item.number4,
              item.number5,
            ].filter(Boolean),
            type: item.forwarding_type,
            callerId:
              item.caller_id === "dialed"
                ? CallerId.dialedNumber
                : item.caller_id === "source"
                ? CallerId.callingNumber
                : item.caller_id || CallerId.callingNumber,
          } as CallForwardingDetails)
      );
  }

  public async updateCallForwarding(
    callForwarding: CallForwardingDetails
  ): Promise<void> {
    await this.api.post(urls.updateCallForwarding, {
      from_number: callForwarding.myNumber,
      number1: callForwarding.numbers[0],
      forwarding_type: callForwarding.type,
      ...(callForwarding.numbers[1]
        ? { number2: callForwarding.numbers[1] }
        : {}),
      ...(callForwarding.numbers[2]
        ? { number3: callForwarding.numbers[2] }
        : {}),
      ...(callForwarding.numbers[3]
        ? { number4: callForwarding.numbers[3] }
        : {}),
      ...(callForwarding.numbers[4]
        ? { number5: callForwarding.numbers[4] }
        : {}),
      caller_id: callForwarding.callerId,
      active: callForwarding.active,
    });
  }

  public getEmptyCallForwarding(myNumber: string): CallForwardingDetails {
    return {
      myNumber,
      active: false,
      numbers: [""],
      type: ForwardingType.single,
      callerId: CallerId.callingNumber,
    };
  }
}
