import { Contact } from 'models/Contact';
import { useMemo } from 'react';


// numbersSearch - search for digital representation of searchPattern in strings (normally phone numbers of contacts)
export function useSearch<T>({ items, fields, searchPattern, numbersSearch }: { items: T[], fields: (keyof T)[], searchPattern: string, numbersSearch?: undefined | ((item: T) => string[]) }): T[] {
  const rx: RegExp | null = useMemo(() => {
    try {
      return new RegExp(`(^|\\b)${searchPattern}`, 'i');
    } catch {
      return null;
      // ignore bad rx
    }
  }, [searchPattern]);

  const digitalSearchPattern = useMemo(() => searchPattern.replace(/[^0-9]/, ''), [searchPattern]);

  const filteredItems: T[] = !searchPattern || !rx ? items : items.filter(item => {
    for (const field of fields) {
      if (rx && rx.test(String(item[field]) || '')) {
        return true;
      }
    }
    if (numbersSearch && digitalSearchPattern) {
      return numbersSearch(item)?.some((number: string) => number.replace(/[^0-9]/, '').includes(digitalSearchPattern));
    }
    return false;
  });

  return filteredItems;
};

export function useContactSearch({ contacts, searchPattern }: { contacts: Contact[], searchPattern: string }): Contact[] {
  return useSearch({ items: contacts, fields: ['name'], searchPattern, numbersSearch: c => c.numbers });
};
