import React, { useRef } from 'react';
import { Toast } from 'primereact/toast';
import { toastKeeper } from 'helpers/toast';
import './BasicLayout.sass';
import { useAuthService } from 'hooks/services/useAuthService';
import { useBrandService } from 'hooks/services/useBrandService';
import useFcmTokenRequest from 'hooks/useFcmTokenRequest';

export const BasicLayout = ({ ...props }) => {

  toastKeeper.toastRef = useRef<Toast>(null);
  const authService = useAuthService();
  useBrandService(); // ensure it's initialized ASAP
  useFcmTokenRequest(); // get notification permission ASAP and request FCM token

  return (
    <main className={`App-Web-Phone auto-flex ${authService.isAutoLoggedIn ? 'embedded' : 'self-hosted'}`}>
      <div className="layout-component auto-flex"  {...props} />
      <Toast ref={toastKeeper.toastRef} />
    </main>
  );
}
