import { Direction, getMessageDescription, Message, MessageType } from 'models/Message';
import { FiVoicemail, FiArrowUpRight, FiArrowDownLeft } from 'react-icons/fi';
import { SiAudiomack } from 'react-icons/si';
import { CallIcon } from 'components/CallIcon/CallIcon';
import './LastMessage.sass';

export const LastMessageDescription = ({ lastMessaage }: { lastMessaage: Message }) => {
  const { type, direction } = lastMessaage;
  const out = direction === Direction.out;
  return (
    <div className="contact-last-message-component">
      <span className="icon">
        {type === MessageType.sms ? (out ? <FiArrowUpRight /> : <FiArrowDownLeft />)
        : type === MessageType.call ? <CallIcon call={lastMessaage} />
        : type === MessageType.callRecording ? <SiAudiomack />
        : type === MessageType.voicemail ? <FiVoicemail />
        : null}
      </span>
      <div>{getMessageDescription(lastMessaage)}</div>
    </div>
  );
}
