import React, { useEffect, useState } from 'react';
import './Login.sass';
import { useAuthService } from 'hooks/services/useAuthService';
import { Loader } from 'components/Loader/Loader';
import { useNavigate } from 'react-router';
import { routes } from 'const/routes';

export const Logout = () => {

  const authService = useAuthService();
  const [loggedOut, setLoggedOut] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    authService.logout().then(() => setLoggedOut(true));
  }, [authService, setLoggedOut]);

  useEffect(() => {
    if (loggedOut) {
      navigate(routes.login);
    }
  }, [loggedOut, navigate]);

  return <Loader />;

};
