import { CallState, Direction, Message } from 'models/Message';
import { FiPhoneCall, FiPhoneIncoming, FiPhoneOutgoing, FiPhoneMissed } from 'react-icons/fi';

export const CallIcon = ({ call }: { call: Message }) => {
  switch (call.callState) {
    case CallState.connecting:
      return <FiPhoneCall />;
    case CallState.missed:
    case CallState.rejected:
    case CallState.cancelled:
    case CallState.noAnswer:
    case CallState.failed:
      return <FiPhoneMissed />
    default:
      return call.direction === Direction.in
        ? <FiPhoneIncoming />
        : <FiPhoneOutgoing />;
  }
}