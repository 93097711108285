import React, { useCallback, useEffect, useState } from 'react';
import { useSmsListService } from 'hooks/services/useSmsListService';
import { InputText } from 'primereact/inputtext';
import { Loader } from 'components/Loader/Loader';
import { usePrevious } from 'hooks/usePrevious';
import { Button } from 'primereact/button';
import { Contact } from 'models/Contact';
import './FormFields.scss';
import { Dialog } from 'primereact/dialog';

export const FormFields = ({ contact }: { contact: Contact }) => {
  
  const smsListService = useSmsListService();

  const list = smsListService.currentSmsList;

  const contactData = (list?.contactData || []).find(c => (contact?.numbers || []).includes(c.number));

  const [fieldValues, setFieldValues] = useState<Record<string, string>>(contactData?.fields || {});
  const [newFieldVisible, setNewFieldVisible] = useState(false);
  const [newFieldName, setNewFieldName] = useState<string | undefined>(undefined);

  const setFieldVariable = (variable: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
    setFieldValues({ ...fieldValues, [variable]: e.target.value });
  };

  const commitField = useCallback((variable: string) => async () => {
    await smsListService.updateFieldValue(list!, contactData!, variable, fieldValues[variable]);
  }, [contactData, fieldValues, list, smsListService]);

  const ids = [list?.id || '', contact?.id || ''].join('+');
  const prevIds = usePrevious(ids);

  useEffect(() => {
    if (ids === prevIds) {
      return;
    }
    Promise.all(Object.keys(fieldValues).map(variable => commitField(variable)));
    setTimeout(() => {
      setFieldValues(contactData?.fields || {});
    });
  }, [ids, prevIds, contactData?.fields, commitField, fieldValues]);
 
  const onNewFieldNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewFieldName(e.target.value);
  }

  const showNewField = () => {
    setNewFieldVisible(true);
  }

  const hideNewField = () => {
    setNewFieldVisible(false);
    setNewFieldName('');
  }

  const addNewField = () => {
    if (!newFieldName || !newFieldName.trim()) {
      setNewFieldName(undefined);
      return;
    }
    smsListService.addNewField(newFieldName);
    setNewFieldName(undefined);
  }

  if (!list || !contactData) {
    return null;
  }

  return (
    <div className="form-fields-component">
      {smsListService.fieldsLoading && <div className="loader"><Loader /></div>}
      <h4>Variables</h4>
      <div className="variable-count">
        {list.variables.length
          ? `${list.variables.length} variable${list.variables.length > 1 ? 's' : ''}`
          : 'No variables yet'
        }
      </div>
      <div className="form-fields">
        {list.variables.map(variable => {
          const id = [list.id, contactData.number, variable].join('/');
          return (
            <div className="field" key={variable}>
              <label htmlFor={id} className="block">{variable}</label>
              <InputText
                id={id}
                key={variable}
                className="p-inputtext-lg"
                value={fieldValues[variable] || ''}
                onChange={setFieldVariable(variable)}
                onBlur={commitField(variable)}
              />
            </div>
          );
        })}
        <div className="add-new-field-btn">
          <Button className="p-button-rounded" onClick={showNewField}>Add new variable</Button>
        </div>
      </div>
      <Dialog header="New Variable" visible={newFieldVisible} onHide={hideNewField}>
        <div>
          <InputText
            value={newFieldName}
            onChange={onNewFieldNameChange}
            onKeyDown={e =>
              (e.key === 'Enter' && addNewField()) ||
              (e.key === 'Escape' && hideNewField())
            }
            placeholder="Variable name"
            title="Variable name"
          />
          <button className="p-button" onClick={addNewField}>OK</button>
        </div>
      </Dialog>

    </div>
  );
}
