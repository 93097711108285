import React, { useCallback, useMemo } from "react";
import { useNavigationService } from "hooks/services/useNavigationService";
import { TabPanel, TabView } from "primereact/tabview";
import { pageInfo } from "models/navigation";
import "./PageTabs.sass";

export const PageTabs = () => {
  const navigation = useNavigationService();

  const pages = useMemo(
    () => (navigation.mode && navigation.pagesByMode[navigation.mode]) || [],
    [navigation.mode, navigation.pagesByMode]
  );

  const { page } = navigation;
  const pageIndex = page ? pages.indexOf(page) : -1;

  const setPageIndex = useCallback(
    ({ index }: { index: number }) => {
      const nextPage = pages[index];
      navigation.page = nextPage;
    },
    [navigation, pages]
  );

  if (pages.length <= 1) {
    return null;
  }

  return (
    <div className="page-tabs-component">
      <TabView activeIndex={pageIndex} onTabChange={setPageIndex}>
        {pages.map((p) => (
          <TabPanel key={p} header={pageInfo[p].name} />
        ))}
      </TabView>
    </div>
  );
};
