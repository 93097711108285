import { environment as defaultEnvironment } from './default';
import { environment as devEnvironment } from './dev';
import { environment as testEnvironment } from './test';
import { environment as prodEnvironment } from './prod';

export const environment = {
  development: devEnvironment,
  test: testEnvironment,
  production: prodEnvironment,
}[process.env.NODE_ENV || ''] || defaultEnvironment;
