import { Contact } from "models/Contact";
import { Id } from "models/common";
import { Message, MessageType } from "models/Message";
import { formatPhoneNumber, toDigitalNumber } from "helpers/phone";
import { storageKeys } from "const/storage-keys";
import { getStorageItem, setStorageItem } from "helpers/storage";
import _ from "lodash-es";
import { AuthService, useAuthService } from "./useAuthService";
import { Page } from "models/navigation";
import { useService, Service, UpdateDispatcher } from "./useService";

const ud: UpdateDispatcher = new Set();

export function useContactService(): ContactService {
  const authService = useAuthService();
  return useService(ud, ContactService, { authService });
}

export class ContactService extends Service {
  public static serviceName = "ContactService";

  private _contacts: Contact[] =
    getStorageItem<Contact[]>(storageKeys.contacts) || [];
  private _current: Contact | undefined; // must be one of this.contacts or undefined

  // WARNING: don't forget to care about data index integrity!
  private contactIndexById: Record<string, Contact> = _.fromPairs(
    this.contacts.map((c) => [c.id, c])
  );
  private contactIndexByNumber: Record<string, Contact> = _.fromPairs(
    _.flatten(this.contacts.map((c) => c.numbers.map((n) => [n, c])))
  );
  private onLoginHandler: Promise<void> | null = null;

  private readonly authService: AuthService;

  constructor({ authService }: { authService: AuthService }) {
    super({ authService });
    this.authService = authService;
  }

  public update() {
    setStorageItem(storageKeys.contacts, this.contacts);
    this.authService.account.customData.contacts = this.contacts;
    this.authService.saveCustomData();
    super.update();
  }

  public async init() {
    if (this.authService.authenticated) {
      this.onLoginHandler = this.onLogin();
    }
    this.authService.onLoginHandlers.push(
      () => (this.onLoginHandler = this.onLoginHandler || this.onLogin())
    );
    this.authService.onLogoutHandlers.push(() => {
      this._contacts = [];
      this._current = undefined;
      this.contactIndexById = {};
      this.contactIndexByNumber = {};
      this.onLoginHandler = null;
    });
    if (this.authService.authenticated) {
      await this.onLoginHandler;
    }
  }

  private async onLogin() {
    if (this.authService.account.customData.contacts?.length > 0) {
      this._contacts = this.authService.account.customData.contacts;
      for (const contact of this._contacts) {
        this.contactIndexById[contact.id] = contact;
        for (const contactNumber of contact.numbers) {
          this.contactIndexByNumber[contactNumber] = contact;
        }
      }
    }
  }

  public get contacts(): Contact[] {
    return this._contacts;
  }

  public getContactsForPage(page: Page): Contact[] {
    switch (page) {
      case Page.callHistory:
        return this.contacts.filter((c) => c.hasCalls);
      case Page.callRecordings:
        return this.contacts.filter((c) => c.hasCallRecordings);
      case Page.sms:
        return this.contacts.filter((c) => c.hasSms || c.hasMms);
      case Page.gallery:
        return this.contacts.filter((c) => c.hasMms);
      case Page.voicemails:
        return this.contacts.filter((c) => c.hasVoicemails);
      case Page.fax:
        return this.contacts.filter((c) => c.hasFax);
      default:
        return this.contacts;
    }
  }

  public get current(): Contact | undefined {
    return this._current;
  }
  public set current(value: Contact | undefined) {
    this._current = this._contacts.find((c) => c.id === value?.id);
    this.update();
  }

  public getContactById(contactId: Id): Contact | undefined {
    return this.contactIndexById[contactId];
  }

  public getContactByNumber(number: string): Contact | undefined {
    return this.contactIndexByNumber[toDigitalNumber(number)];
  }

  public addNewContact(number: string, lastMessage?: Message): Contact {
    number = toDigitalNumber(number);
    const existingContact = this.contacts.find((c) =>
      c.numbers.includes(number)
    );
    if (
      existingContact &&
      lastMessage &&
      (!existingContact.lastMessage ||
        existingContact.lastMessage.at < lastMessage.at)
    ) {
      existingContact.lastMessage = lastMessage;
      if (lastMessage.type === MessageType.sms) {
        existingContact.lastSmsMessage = lastMessage;
        existingContact.hasSms = true;
      }
      if (lastMessage.type === MessageType.mms) {
        existingContact.lastMmsMessage = lastMessage;
        existingContact.hasMms = true;
      }
      if (lastMessage.type === MessageType.fax) {
        existingContact.lastFaxMessage = lastMessage;
        existingContact.hasFax = true;
      }
      existingContact.hasCalls =
        existingContact.hasCalls || lastMessage.type === MessageType.call;
      existingContact.hasVoicemails =
        existingContact.hasVoicemails ||
        lastMessage.type === MessageType.voicemail;
      this.update();
      return existingContact;
    }
    if (existingContact && !lastMessage) {
      return existingContact;
    }
    const contact: Contact = {
      id: number,
      name:
        this.authService.account.customData.contacts.find((c) =>
          c.numbers.includes(number)
        )?.name || "",
      numbers: [number],
      currentNumber: number,
      lastMessage,
      lastSmsMessage:
        lastMessage?.type === MessageType.sms ? lastMessage : undefined,
      hasCalls: false,
      hasCallRecordings: false,
      hasSms: false,
      hasMms: false,
      hasVoicemails: false,
      hasFax: false,
    };
    this.contacts.push(contact);
    this.contactIndexById[contact.id] = contact;
    for (const contactNumber of contact.numbers) {
      this.contactIndexByNumber[contactNumber] = contact;
    }
    this.update();
    return contact;
  }

  public setCurrentNumber({
    contact = this.current,
    currentNumber,
  }: {
    contact?: Contact;
    currentNumber?: string;
  }) {
    if (!contact) {
      return;
    }
    currentNumber =
      currentNumber || contact.currentNumber || contact.numbers[0];
    if (!contact.numbers.includes(currentNumber)) {
      currentNumber = contact.numbers[0];
    }
    contact.currentNumber = currentNumber;
  }

  public setName(name: string) {
    if (!this.current) {
      return;
    }
    const newName =
      (name || "").trim() || formatPhoneNumber(this.current.numbers[0]);
    if (newName === this.current.name) {
      return;
    }
    this.current.name = newName;
    this.update();
  }

  public deleteContact(contact: Contact) {
    this._contacts = this._contacts.filter((c) => c.id !== contact.id);
    delete this.contactIndexById[contact.id];
    for (const number of contact.numbers) {
      delete this.contactIndexByNumber[number];
    }
    this.current = undefined;
    this.update();
  }
}
