import React from 'react';
import { Loader } from 'components/Loader/Loader';
import { ComposeSmsCampaign } from './ComposeSmsCampaign';
import { useSmsListService } from 'hooks/services/useSmsListService';
import { useSmsCampaignService } from 'hooks/services/useSmsCampaignService';
import { ChatCampaignMessage } from './ChatCampaignMessage';
import "./SmsCampaigns.scss";

export const SmsCampaigns = () => {
  
  const { currentSmsList: smsList } = useSmsListService();
  const campaignService = useSmsCampaignService();

  const messages = campaignService.messages(smsList?.id || 0);
  const loading = campaignService.smsCampaignsLoading;

  return (
    <div className="sms-campaigns-component auto-flex">
      <div className="messages auto-flex">
        <ul>
          {loading && <li><Loader /></li>}
          {messages.map(msg =>
            <ChatCampaignMessage key={`${msg.smsCampaignRunId}@${msg.at}`} message={msg} />
          )}
        </ul>
        {messages.length === 0 && !loading && <div className="no-messages">No messages yet</div>}
      </div>
      <ComposeSmsCampaign />
    </div>
  );

}
