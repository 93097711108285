import _ from "lodash-es";
import { storageKeys } from "const/storage-keys";
import { ApiService, useApiService } from "./useApiService";
import { getStorageItem, setStorageItem } from "helpers/storage";
import { urls } from "const/urls";
import { Id } from "models/common";
import { Media, MediaType } from "models/Media";
import { AuthService, useAuthService } from "./useAuthService";
import { useService, Service, UpdateDispatcher } from "./useService";

const ud: UpdateDispatcher = new Set();

export function useMediaService(): MediaService {
  const api = useApiService();
  const auth = useAuthService();
  return useService(ud, MediaService, { api, auth });
}

export class MediaService extends Service {
  public static serviceName = "MediaService";

  public media: Media[] = getStorageItem(storageKeys.media) || [];

  // WARNING: don't forget to care about data index integrity!
  private mediaById: Record<string, Media> = _.fromPairs(
    this.media.map((m) => [m.id, m])
  );
  private mediaByUrl: Record<string, Media> = _.fromPairs(
    this.media.map((m) => [m.url, m])
  );
  private onLoginHandler: Promise<void> | null = null;

  private readonly api: ApiService;
  private readonly auth: AuthService;

  constructor({ api, auth }: { api: ApiService; auth: AuthService }) {
    super({ api, auth });
    this.api = api;
    this.auth = auth;
  }

  public update() {
    setStorageItem(storageKeys.media, this.media);
    super.update();
  }

  public async init(): Promise<void> {
    // legacy migration - add `type` from `document_type`, and `description` from `label`
    if (this.media.every((m) => m.type === undefined)) {
      this.media = [];
      this.mediaById = {};
      this.mediaByUrl = {};
      setStorageItem(storageKeys.media, this.media);
      setStorageItem(storageKeys.documentCount, 0);
    }

    if (this.auth.authenticated && !this.auth.callForwarding) {
      this.onLoginHandler = this.loadMedia();
    }
    this.auth.onLoginHandlers.push(
      () => (this.onLoginHandler = this.onLoginHandler || this.loadMedia())
    );
    this.auth.onLogoutHandlers.push(() => {
      this.media = [];
      this.mediaById = {};
      this.mediaByUrl = {};
    });
  }

  public async loadMedia(): Promise<void> {
    interface DocumentsResponse {
      count: number;
      next?: string;
      results: Array<{
        id: Id;
        timestamp: string; // WARNING: that's not seconds (as in other endpoints)! instead, it's date.toISOString()
        content_type: string;
        url: string;
        document_type?: string;
        label: string;
      }>;
    }

    const firstPageUrl = urls.mediaDocuments.replace(
      ":customerNumber",
      this.auth.customerNumber
    );
    let url = firstPageUrl;
    do {
      const response = await this.api.get<DocumentsResponse>(url);
      if (url === firstPageUrl) {
        // page 1
        if (
          response.count === getStorageItem<number>(storageKeys.documentCount)
        ) {
          break;
        }
        setStorageItem(storageKeys.documentCount, response.count || 0);
      }
      for (const x of response.results.filter(
        (doc) => !this.mediaById[doc.id] && this.mediaByUrl[doc.url]
      )) {
        const media = this.mediaByUrl[x.url];
        media.id = x.id;
      }
      const newMedia: Media[] = response.results
        .filter(
          (doc) =>
            !this.mediaByUrl[doc.url] &&
            (doc.content_type.startsWith("audio/") ||
              doc.content_type.startsWith("video/") ||
              doc.content_type.startsWith("image/"))
        )
        .map(
          (x) =>
            ({
              id: x.id,
              url: x.url,
              mimeType: x.content_type,
              type: x.document_type || MediaType.None,
              description: x.label,
            } as Media)
        );
      for (const media of newMedia) {
        this.addNewMedia(media);
      }
      url = response.next || "";
    } while (url);
  }

  addNewMedia(media: Media): Media {
    this.media.push(media);
    this.updateMedia(media);
    return media;
  }

  updateMedia(media: Media): void {
    media.type = media.type || MediaType.None;
    if (media.id) {
      this.mediaById[media.id] = media;
    }
    if (media.url) {
      this.mediaByUrl[media.url] = media;
    }
    this.update();
  }

  removeMedia(id: Id): void {
    const index = this.media.findIndex((m) => m.id === id);
    if (index === -1) {
      console.warn(`Media with id ${id} not found`);
      return;
    }
    const media = this.media[index];
    this.media.splice(index, 1);
    if (media.id) {
      delete this.mediaById[media.id];
    }
    if (media.url) {
      delete this.mediaByUrl[media.url];
    }
    this.update();
  }

  async uploadMedia(
    mediaData: Partial<Media> & { file: File }
  ): Promise<Media | undefined> {
    const { file } = mediaData;
    if (!file) {
      return undefined;
    }
    const media: Media = {
      id: `temp-${Date.now()}`,
      ...mediaData,
      file,
      size: file.size,
      mimeType: file.type,
      fileName: file.name,
      type: mediaData.type || MediaType.None,
    };
    // const fileContent = await getFileContent(file);
    // const base64Content = btoa(fileContent);
    interface UploadResponse {
      id: string;
    }
    let response: UploadResponse;
    try {
      response = await this.api.post<UploadResponse>(
        urls.uploadMediaDocument.replace(
          ":customerNumber",
          this.auth.customerNumber
        ),
        {},
        {
          form: {
            document: file,
            file_name: media.fileName || "file",
            document_type: media.type,
            ...(media.description && { label: media.description }),
          },
        }
      );
    } catch (e) {
      console.error(e);
      return undefined;
    }
    if (!response.id) {
      return undefined;
    }
    const oldMediaId = media.id;
    media.id = response.id;
    if (this.getMediaById(oldMediaId)) {
      this.updateMedia(media);
    }
    return media;
  }

  public getMediaById(id: Id): Media | undefined {
    return this.mediaById[id];
  }

  public getMediaByUrl(url: string): Media | undefined {
    return this.mediaByUrl[url];
  }

  public getMediasByType(type: MediaType): Media[] {
    console.log(this.media);
    return this.media.filter((m) => m.type === type);
  }
}
