export const decodeContent = (encodedContent: any, coding: number): string => {
  if (!encodedContent) {
    return '';
  }
  switch (coding) {
    case 0:
      return getEncoder('ascii').decode(hexToArrayBuffer(encodedContent));
    case 1:
      return getEncoder('ascii').decode(hexToArrayBuffer(encodedContent));
    case 3:
      return getEncoder('latin1').decode(hexToArrayBuffer(encodedContent));
    case 4:
      return getEncoder('utf-8').decode(hexToArrayBuffer(encodedContent));
    case 8:
      return getEncoder('utf-16').decode(hexToArrayBuffer(encodedContent));
    default:
      return encodedContent;
  }
};
Object.assign(window, { decodeContent });

const getEncoder = (encodingType: string) => {
  return new TextDecoder(encodingType);
};

const hexToArrayBuffer = (input: string): ArrayBufferLike => {
  if (typeof input !== 'string') {
    throw new TypeError('Expected input to be a string');
  }

  if (input.length % 2 !== 0) {
    throw new RangeError('Expected string to be an even number of characters');
  }

  const view = new Uint8Array(input.length / 2);

  for (let i = 0; i < input.length; i += 2) {
    view[i / 2] = parseInt(input.substring(i, i + 2), 16);
  }

  return view.buffer;
}

export default decodeContent;
