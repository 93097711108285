import _ from "lodash-es";
import { storageKeys } from "const/storage-keys";
import { ApiService, useApiService } from "./useApiService";
import { getStorageItem, setStorageItem } from "helpers/storage";
import { urls } from "const/urls";
import { Id, PhoneNumber } from "models/common";
import { MediaService, useMediaService } from "./useMediaService";
import { Direction, Message, MessageType } from "models/Message";
import { ContactService, useContactService } from "./useContactService";
import { MyNumberService, useMyNumberService } from "./useMyNumberService";
import { Feature } from "models/MyNumber";
import { sortByAtDescFunc } from "helpers/collection";
import { MediaType } from "models/Media";
import { useService, Service, UpdateDispatcher } from "./useService";
import { AuthService, useAuthService } from "./useAuthService";

const ud: UpdateDispatcher = new Set();

export function useVoicemailService(): VoicemailService {
  const api = useApiService();
  const auth = useAuthService();
  const myNumberService = useMyNumberService();
  const contactService = useContactService();
  const mediaService = useMediaService();
  return useService(ud, VoicemailService, {
    api,
    auth,
    myNumberService,
    contactService,
    mediaService,
  });
}

export class VoicemailService extends Service {
  public static serviceName = "VoicemailService";

  private voicemails: Message[]; // init value is set in constructor

  // WARNING: don't forget to care about data index integrity!
  private voicemailById: Record<string, Message>; // init value is set in constructor
  private voicemailsByContactId: Record<string, Message[]>; // init value is set in constructor
  private myVoicemailPhoneNumbers: string[] = [];

  private readonly api: ApiService;
  private readonly auth: AuthService;
  private readonly myNumberService: MyNumberService;
  private readonly contactService: ContactService;
  private readonly mediaService: MediaService;

  constructor({
    api,
    auth,
    myNumberService,
    contactService,
    mediaService,
  }: {
    api: ApiService;
    auth: AuthService;
    myNumberService: MyNumberService;
    contactService: ContactService;
    mediaService: MediaService;
  }) {
    super({ api, auth, myNumberService, contactService, mediaService });
    this.api = api;
    this.auth = auth;
    this.myNumberService = myNumberService;
    this.contactService = contactService;
    this.mediaService = mediaService;

    // init values
    this.voicemails = getStorageItem(storageKeys.voicemails.messages) || [];
    this.voicemailById = _.fromPairs(
      this.voicemails.filter((m) => m.entityId).map((m) => [m.entityId, m])
    );
    this.voicemailsByContactId = _.fromPairs(
      _.toPairs(
        _.groupBy(
          this.voicemails.map((m) => ({
            vm: m,
            contactId:
              contactService.getContactByNumber(m.contactNumber)?.id || "",
          })),
          (x: { vm: Message; contactId: Id }) => x.contactId
        )
      ).map(([contactId, items]: [Id, { vm: Message; contactId: Id }[]]) => [
        contactId,
        items.map((x) => x.vm),
      ])
    );
  }

  public update() {
    setStorageItem(storageKeys.voicemails.messages, this.voicemails);
    super.update();
  }

  public async init(): Promise<void> {
    this.onDependentServiceUpdate({ serviceName: MyNumberService.name });
    this.auth.onLoginHandlers.push(() =>
      this.onDependentServiceUpdate({ serviceName: MyNumberService.name })
    );
    this.auth.onLogoutHandlers.push(() => {
      this.voicemails = [];
      this.voicemailById = {};
      this.voicemailsByContactId = {};
    });
  }

  public async onDependentServiceUpdate({
    serviceName,
  }: {
    serviceName: string;
  }) {
    switch (serviceName) {
      case MyNumberService.name: {
        if (this.auth.callRecording || this.auth.callForwarding) {
          return;
        }
        const myVoicemailNumbers = this.myNumberService.myNumbers.filter((n) =>
          n.features.includes(Feature.voicemails)
        );
        const newPhoneNumbers = myVoicemailNumbers
          .filter((n) => !this.myVoicemailPhoneNumbers.includes(n.number))
          .map((n) => n.number);
        this.myVoicemailPhoneNumbers = myVoicemailNumbers.map((n) => n.number);
        if (!newPhoneNumbers.length) {
          break;
        }
        await this.loadVoicemails(newPhoneNumbers);
        this.update();
        break;
      }
    }
  }

  private async loadVoicemails(
    myVoicemailPhoneNumbers: PhoneNumber[]
  ): Promise<void> {
    interface VoicemailsResponse {
      count: number;
      next?: string;
      results: Array<{
        id: number;
        timestamp: string; // WARNING: that's not seconds (as in other endpoints)! instead, it's date.toISOString()
        from_number: string;
        to_number: string;
        download_url: string;
      }>;
    }
    await Promise.all(
      myVoicemailPhoneNumbers.map(async (n) => {
        const firstPageUrl = urls.voicemails.replace(":myNumber", n);
        let url = firstPageUrl;
        do {
          const response = await this.api.get<VoicemailsResponse>(url);
          if (url === firstPageUrl) {
            // page 1
            if (
              response.count ===
              getStorageItem<number>(storageKeys.voicemails.count)
            ) {
              break;
            }
            setStorageItem(storageKeys.voicemails.count, response.count || 0);
          }
          const newVoicemails: Message[] = response.results
            .filter((x) => !this.voicemailById[x.id.toString()])
            .map((x) => {
              const at = new Date(x.timestamp).getTime();
              const media =
                this.mediaService.getMediaByUrl(x.download_url) ||
                this.mediaService.addNewMedia({
                  id: `voice-mail-${x.id}`,
                  url: x.download_url,
                  type: MediaType.Voicemail,
                });
              return {
                myNumber: x.to_number,
                contactNumber: x.from_number,
                direction: Direction.in,
                type: MessageType.voicemail,
                entityId: x.id.toString(),
                at,
                sentAt: at,
                mediaId: media.id,
              } as Message;
            });
          for (const voicemail of newVoicemails) {
            this.addNewVoicemail(voicemail);
          }
          url = response.next || "";
        } while (url);
      })
    );
    this.voicemails.sort(sortByAtDescFunc);
    for (const contactId of Object.keys(this.voicemailsByContactId)) {
      this.voicemailsByContactId[contactId].sort(sortByAtDescFunc);
    }
  }

  addNewVoicemail(voicemail: Message) {
    this.voicemails.push(voicemail);
    if (voicemail.entityId) {
      this.voicemailById[voicemail.entityId] = voicemail;
    }
    const contact =
      this.contactService.getContactByNumber(voicemail.contactNumber) ||
      this.contactService.addNewContact(voicemail.contactNumber, voicemail);
    contact.hasVoicemails = true;
    if (contact?.id) {
      this.voicemailsByContactId[contact.id] =
        this.voicemailsByContactId[contact.id] || [];
      this.voicemailsByContactId[contact.id].push(voicemail);
    }
  }

  public getMessagesByContactId(contactId: Id): Message[] {
    return this.voicemailsByContactId[contactId] || [];
  }

  public getAllMessages(): Message[] {
    return this.voicemails;
  }

  get totalMessageCount(): number {
    return this.voicemails.length;
  }
}
