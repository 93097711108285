import React, { useEffect } from 'react';
import { initializeApp } from 'firebase/app';
import { getMessaging, getToken } from "firebase/messaging";
import { environment } from '../environments';

const useFcmTokenRequest = (): string => {
  const [fcmToken, setFcmToken] = React.useState<string>('');
  useEffect(() => {
    const initializeFirebase = async () => {
      try {
        const app = initializeApp(environment.firebaseConfig);
        const messaging = getMessaging(app);
        const permissionGranted = (await Notification.requestPermission()) === 'granted';
        if (!permissionGranted) {
          console.warn('Notifications are not allowed, FCM token will not be requested.');
          return;
        }
        const token = await getToken(messaging, { vapidKey: environment.firebaseVapidKey });
        console.log('FCM Token:', token);
        setFcmToken(token);
      } catch (error) {
        console.error('Error initializing Firebase:', error);
      }
    };

    initializeFirebase();
  }, []);

  return fcmToken;
};

export default useFcmTokenRequest;