import { Toast, ToastMessageType } from "primereact/toast";
import { RefObject } from "react";

export const toastKeeper: { toastRef: RefObject<Toast> | null } = { toastRef: null };
Object.assign(window, { toastKeeper }); // that's a 'hack' necessary, so that non-UI layer (services) has avility to notify user about fails

export const showToast = (message: ToastMessageType) => {
  if (toastKeeper.toastRef?.current) {
    toastKeeper.toastRef?.current.show(message);
  }
}
