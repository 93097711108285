import React, { useEffect, useState } from "react";
import _ from "lodash-es";
import {
  Direction,
  getMessageDescription,
  Message,
  MessageType,
} from "models/Message";
import { formatPhoneNumber } from "helpers/phone";
import { getFormattedTimeFromTimestamp } from "helpers/date";
import { useMediaService } from "hooks/services/useMediaService";
import { useCallService } from "hooks/services/useCallService";
import { useMyNumberService } from "hooks/services/useMyNumberService";
import { CallIcon } from "components/CallIcon/CallIcon";
import { Link } from "react-router-dom";
import { routes } from "const/routes";
import { setStorageItem } from "helpers/storage";
import { storageKeys } from "const/storage-keys";
import { MediaMessageContent } from "./MediaMessageContent";
import "./ChatMessage.scss";
import { MdRefresh } from "react-icons/md";
import { ImSpinner } from "react-icons/im";
import { BsFillExclamationTriangleFill } from "react-icons/bs";
import { AppMode, pageByMessageType, pageInfo } from "models/navigation";
import { useChatService } from "hooks/services/useChatService";
import { confirmDialog } from "primereact/confirmdialog";
import { Button } from "primereact/button";
import { useMapService } from "hooks/services/useMapService";
import { useNavigationService } from "hooks/services/useNavigationService";
import { environment } from "environments";
import { urlFromApiUrl } from "helpers/file";
import { useApiService } from "hooks/services/useApiService";

export const ChatMessage = ({ message }: { message: Message }) => {
  const api = useApiService();
  const mediaService = useMediaService();
  const myNumberService = useMyNumberService();
  const callService = useCallService();
  const chatService = useChatService();
  const mapService = useMapService();
  const navigation = useNavigationService();

  const media = message.mediaId
    ? mediaService.getMediaById(message.mediaId)
    : undefined;

  const callBack = (call: Message) => () => {
    if (myNumberService.current?.number !== call.myNumber) {
      myNumberService.setCurrent(call.myNumber);
    }
    setStorageItem(
      storageKeys.calls.dialingNumber,
      call.contactNumber,
      sessionStorage
    );
    callService.dial(call.contactNumber);
  };

  const sendMessage = (message: Message) => () => {
    chatService.sendMessage(message);
  };

  const deletable = message.type === MessageType.fax;
  const deleteMessage = () => chatService.deleteMessage(message);

  const onDeleteMessage = () => {
    confirmDialog({
      message: "Do you want to delete this message?",
      header: "Delete Confirmation",
      icon: "pi pi-info-circle",
      acceptClassName: "p-button-danger",
      accept: deleteMessage,
    });
  };

  const mapsLocation =
    message.type === MessageType.sms &&
    message.body?.match(/\/maps:([-0-9.]+),([-0-9.]+)/);

  const openMaps = () => {
    if (!mapsLocation) {
      return;
    }
    mapService.selectedLocation = {
      latitude: parseFloat(mapsLocation[1]),
      longitude: parseFloat(mapsLocation[2]),
      label: formatPhoneNumber(message.contactNumber),
    };
    navigation.mode = AppMode.map;
  };

  const mapModeAvailable = navigation.modes.includes(AppMode.map);

  const [mediaUrl, setMediaUrl] = useState("");
  useEffect(() => {
    if (media?.url?.startsWith(environment.api.baseUrl)) {
      urlFromApiUrl(api, media.url).then((url) => setMediaUrl(url));
    } else {
      setMediaUrl(media?.url || "");
    }
  }, [api, media?.url]);

  return (
    <li
      className={`chat-message-component message-type-${message.type} ${
        message.direction === Direction.out && "my-message"
      } ${message.failed ? " failed" : ""}`}
    >
      <div className="message-content">
        {[MessageType.voicemail, MessageType.callRecording].includes(
          message.type
        ) ? (
          <audio controls={true} src={mediaUrl} />
        ) : mapsLocation ? (
          <>
            <i>Shared location </i>
            {mapModeAvailable ? (
              <Button icon="pi pi-compass" onClick={openMaps}></Button>
            ) : (
              <a
                href={`https://www.google.com/maps/search/?api=1&query=${mapsLocation[1]},${mapsLocation[2]}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Button icon="pi pi-compass"></Button>
              </a>
            )}
          </>
        ) : message.type === MessageType.sms ||
          message.type === MessageType.mms ? (
          <>
            {message.body
              ? _.flatten(
                  getMessageDescription(message)
                    .split("\n")
                    .map((messageLine, i) => [
                      <span key={i}>{messageLine}</span>,
                      <br key={`${i}_br`} />,
                    ])
                )
              : null}
            {media ? <MediaMessageContent media={media} /> : null}
          </>
        ) : message.type === MessageType.call ? (
          <div className="clickable call-message" onClick={callBack(message)}>
            <CallIcon call={message} />
            <div>{getMessageDescription(message)}</div>
          </div>
        ) : message.type === MessageType.fax ? (
          <>
            {media && <MediaMessageContent media={media} />}
            {!media?.url &&
              !message.failed &&
              `Fax ${
                message.direction === Direction.in
                  ? "received"
                  : message.sending
                  ? "is sending"
                  : "sent"
              }`}
          </>
        ) : null}
        {message.failed && (
          <div className="fail-description">
            <BsFillExclamationTriangleFill />{" "}
            {message.failed === true
              ? "Message sending failed."
              : message.failed}
          </div>
        )}
      </div>
      <div className="my-number">
        {message.direction === Direction.out ? "Outbound " : "Inbound "}
        <Link to={pageInfo[pageByMessageType[message.type]].route}>
          {message.type}
        </Link>
        {message.direction === Direction.out ? " to " : " from "}
        {formatPhoneNumber(message.contactNumber)}
        {message.direction === Direction.out ? " from " : " to "}
        <Link to={routes.home}>{formatPhoneNumber(message.myNumber)}</Link>
      </div>
      <div className="time">{getFormattedTimeFromTimestamp(message.at)}</div>
      <div
        className={`status status-${
          message.sending ? "sending" : message.failed ? "failed" : "ok"
        }`}
      >
        {message.sending ? (
          <ImSpinner />
        ) : message.failed && !message.mediaId ? (
          <MdRefresh
            onClick={sendMessage(message)}
            title="Retry sending failed message again"
          />
        ) : null}
      </div>
      {deletable && (
        <div className="delete-msg" onClick={onDeleteMessage}>
          ×
        </div>
      )}
    </li>
  );
};
