import React, { useCallback, useEffect, useState } from 'react';
import { useCallService } from 'hooks/services/useCallService';
import { useContactService } from 'hooks/services/useContactService';
import { useMyNumberService } from 'hooks/services/useMyNumberService';
import { useTimer } from 'hooks/useTimer';
import { CallState, Direction } from 'models/Message';
import { Button } from 'primereact/button';
import { FaMicrophoneAltSlash, FaPhoneSlash } from 'react-icons/fa';
import { HiOutlinePause } from 'react-icons/hi';
import { formatTimer } from 'helpers/date';
import './ActiveCall.scss';
import { ContactCard } from 'components/Contacts/ContactCard';
import { BsFillTelephoneFill } from 'react-icons/bs';
import { useNavigate } from 'react-router';
import { routes } from 'const/routes';
import { MdDialpad } from 'react-icons/md';

export const ActiveCall = () => {
  const callService = useCallService();
  const contactService = useContactService();
  const myNumberService = useMyNumberService();
  const navigate = useNavigate();

  const call = callService.currentCall;
/*
  const now = new Date().getTime();
  const entityId = generateGuid();
  const call = {
    myNumber: myNumberService.current?.number,
    contactNumber: '8332782634',
    direction: Direction.in,
    type: MessageType.call,
    entityId,
    at: now, // later will be overriden by startedAt, and finally by endedAt
    sentAt: now,
    callState: CallState.ringing,
  } as Message;
*/


  const timerEnabled = call?.callState === CallState.ongoing;
  const [initialTimerTime, setInitialTimerTime] = useState(0);

  useEffect(() => {
    setInitialTimerTime(call?.callState === CallState.ongoing && call.callStartedAt
      ? new Date().getTime() - call.callStartedAt
      : 0
    );
  }, [call?.callState, call?.callStartedAt]);

  const mutedClick = useCallback(() => callService.muted = !callService.muted, [callService]);
  const onHoldClick = useCallback(() => callService.onHold = !callService.onHold, [callService]);
  const onAnswerClick = useCallback(() => callService.answer(), [callService]);
  const onHangUpClick = useCallback(() => callService.hangUp(), [callService]);
  const dialerClick = useCallback(() => navigate(routes.dialer), [navigate]);

  const currentTime = useTimer({ enabled: timerEnabled, startTime: initialTimerTime });

  const contact = contactService.getContactByNumber(call?.contactNumber || '')!;
  const myNumber = myNumberService.myNumbers.find(n => n.number === call?.myNumber);
  const incomingRinging = Boolean(call && call.direction === Direction.in && call?.callState !== CallState.ongoing);
  
  if (!call) {
    return <em className="active-call-component">No active call</em>;
  }

  return (
    <div className="active-call-component auto-flex">
      <div className="content">
        <div>
          <ContactCard
            contact={contact}
            selectable={false}
            lastMessage={call}
            time={(call?.callState === CallState.ongoing || call?.callState === CallState.ended) ? formatTimer(currentTime) : '' }
          />
          <div className="my-number">{call?.direction === Direction.in ? 'to' : 'from'} {myNumber?.name}</div>
        </div>
        <div className="session-controls d-flex justify-content-center align-items-center">
          <div className="button-row">
            {incomingRinging &&
              <Button
                className="p-button-rounded p-button-success"
                onClick={onAnswerClick}
                title="Answer Call"
              ><BsFillTelephoneFill /></Button>
            }
            {call?.callState === CallState.ongoing && <>
              <Button
                className={`p-button-rounded ${callService.muted ? 'p-button-warning' : 'p-button-secondary'}`}
                onClick={mutedClick}
                title="Mute/unmute"
              ><FaMicrophoneAltSlash /></Button>
              <Button
                className={`p-button-rounded ${callService.onHold ? 'p-button-warning' : 'p-button-secondary'}`}
                onClick={onHoldClick}
                title="Hold/unhold"
              ><HiOutlinePause /></Button>
              <Button
                className="p-button-rounded p-button-secondary"
                onClick={dialerClick}
                title="Dialer"
              ><MdDialpad /></Button>
            </>}
            <Button
              className="p-button-rounded p-button-danger"
              onClick={onHangUpClick}
              title="Hang up"
            ><FaPhoneSlash /></Button>
          </div>
        </div>
      </div>
    </div>
  );
};
