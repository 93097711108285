export const urls = {
  accountInfo: "me", // GET
  myNumbers: "me/numbers", // GET
  brand: "whitelabel/config",
  customData: "communicator/app/:customerNumber",
  callNumbers: "mobile_app/registrations",
  // smsNumbers: "sms/:customerNumber/list",
  // mmsNumbers: "mms/:customerNumber/list",
  // faxNumbers: "fax",
  smsLists: "sms/:customerNumber/lists",
  smsListDetails: "sms/lists/:listId",
  smsListVariables: "sms/lists/:listId/variables",
  createSmsList: "sms/lists", // POST { label, customer_number }
  addSmsListNumber: "sms/lists/:listId", // POST { number, meta_data }
  removeSmsListNumber: "sms/lists/:listId/:number", // DELETE
  addSmsListVariable: "sms/lists/:listId/variables", // POST { variable }
  smsCampaigns: "sms/campaigns",
  createSmsCampaign: "sms/campaigns", // POST { customer_number, label, body }
  updateSmsCampaign: "sms/campaigns/:campaignId", // POST { label, body }
  smsCampaignDetails: "sms/campaigns/:campaignId",
  runSmsCampaign: "sms/campaigns/:campaignId", // PUT { list_id, from_numbers } & optional { coding: 'UCS2' | 'ASCII' | 'UTF8', max_message_count, frequency: 'seconds' | 'minutes' | 'hours', frequency_value }; throttling is "{max_message_count} messages per {frequency_value} {frequency}"
  pauseSmsCampaign: "sms/campaigns/:campaignId/:runId/stop", // PUT
  resumeSmsCampaign: "sms/campaigns/:campaignId/:runId/resume", // PUT optional { from_numbers, coding: 'UCS2' | 'ASCII' | 'UTF8' }
  smsCampaignRuns: "sms/campaign_runs",
  autoResponders: "sms/autoresponders/:myNumber",
  createAutoResponder: "sms/autoresponders/:myNumber", // POST { stopword_match_type, stopword, response, active }
  updateAutoResponder: "sms/autoresponders/:myNumber/:id", // POST { stopword_match_type, stopword, response, active }
  mediaDocuments: "documents/:customerNumber", // GET
  uploadMediaDocument: "documents/:customerNumber", // POST { document: base64, file_name, document_type: one of "CONTRACT" "ORDER" "RATES" "VOICEMAIL" "VOICEMAIL_GREETING" "FAX" "WELCOME_PACKAGE" "QUOTES" "LOAS" "INVOICES", label? }
  messageThreads: "mobile_app/threads/:myNumber",
  threadMessages: "mobile_app/messages/:threadId",
  sendSms: "sms/:myNumber", // POST { to_number, message, reset_unread_count: 1 }
  sendMms: "mms/:myNumber", // POST { to_number, message, attachment: binary }
  inboundMmsMessages: "mms/:myNumber/inbound",
  outboundMmsMessages: "mms/:myNumber/outbound",
  voicemails: "voicemail/received/:myNumber",
  greetings: "voicemail/greetings",
  setGreeting: "voicemail/greetings/:myNumber/:documentId", // PUT
  unsetGreeting: "voicemail/greetings/:myNumber", // DELETE
  faxMessages: "fax/:myNumber",
  sendFax: "fax/:myNumber", // POST { to_number, file_name, document: binary, quality_preset?: 'standard' | 'standard_sharp' | 'fine' | 'fine_sharp' }
  deleteFax: "fax/:myNumber/:faxId", // DELETE
  callHistory: "/mobile_app/call_history", // GET
  addCallHistory: "/mobile_app/call_history", // POST { call_id, self_phone_number, peer_phone_number, direction, status, device_type, device_fcm_id, start_time, answer_time, end_time, error_code, error_cause, error_reason_phrase }
  callForwardings: "inventory/numbers/call_forwarding", // GET
  updateCallForwarding: "inventory/numbers/call_forwarding", // POST
  callRecordings: "voice/call_recording/:customerNumber/list", // GET
  callRecordingAudioSrc: "voice/call_recording/:callId", // GET
  deleteCallRecording: "voice/call_recording/:callId", // DELETE
  callProcedures: "call_proc/endpoints", // GET
  callProcedureNumbers: "call_proc/endpoints/:endpointId/numbers", // GET
  addCallProcedureNumber: "call_proc/endpoints/:endpointId/", // PUT { endpoint_id, number }
  removeCallProcedureNumber: "call_proc/endpoints/:endpointId/numbers/:number", // DELETE
};
