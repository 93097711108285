import { Loader } from "components/Loader/Loader";
import { formatDuration } from "helpers/media";
import { useCallRecordingService } from "hooks/services/useCallRecordingService";
import { useMediaService } from "hooks/services/useMediaService";
import { Direction } from "models/Message";
import { Column } from "primereact/column";
import { DataTable, DataTableFilterMeta } from "primereact/datatable";
import { FilterMatchMode, FilterOperator, FilterService } from "primereact/api";
import { useCallback, useEffect, useRef, useState } from "react";
import { Button } from "primereact/button";
import { downloadFile, urlFromApiUrl } from "helpers/file";
import { getFormattedTimeFromTimestamp } from "helpers/date";
import { useApiService } from "hooks/services/useApiService";
import { DateTime, Id } from "models/common";
import "./CallRecordingTable.sass";
import { formatPhoneNumber } from "helpers/phone";
import { InputText } from "primereact/inputtext";
import { usePrevious } from "hooks/usePrevious";

interface TableRow {
  id: Id;
  at: DateTime;
  formattedDate: string;
  from: string;
  to: string;
  direction: "Inbound" | "Outbound" | "";
  duration: number; // in seconds
  url: string;
}

const getInitialFilters = (): DataTableFilterMeta => ({
  formattedDate: { value: null, matchMode: FilterMatchMode.CONTAINS },
  from: { value: null, matchMode: FilterMatchMode.CONTAINS },
  to: { value: null, matchMode: FilterMatchMode.CONTAINS },
  direction: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
  duration: {
    value: null,
    matchMode: FilterMatchMode.GREATER_THAN_OR_EQUAL_TO,
  },
});

export const CallRecordingTable = () => {
  const callRecordingService = useCallRecordingService();
  const mediaService = useMediaService();
  const api = useApiService();

  const [destroyed, setDestroyed] = useState(false);
  const [tableData, setTableData] = useState<TableRow[]>([]);
  const [mediaUrl, setMediaUrl] = useState("");
  const [playingItemId, setPlayingItemId] = useState("");
  const [filters, setFilters] = useState<DataTableFilterMeta>(
    getInitialFilters()
  );
  const dataTablePlaceholderRef = useRef<HTMLDivElement>(null);

  const loadAllRecords = useCallback(async () => {
    while (!destroyed && callRecordingService.hasMore) {
      await callRecordingService.loadMore();
    }
  }, [callRecordingService, destroyed]);

  useEffect(() => {
    setDestroyed(false);
    return () => {
      setDestroyed(true);
    };
  }, [loadAllRecords]);

  useEffect(() => {
    callRecordingService.ready.then(() => {
      loadAllRecords();
    });
  }, [callRecordingService.ready, loadAllRecords]);

  const loading = callRecordingService.hasMore;

  const play = (row: TableRow) => async () => {
    setMediaUrl("");
    setPlayingItemId(row.id);
    const url = await urlFromApiUrl(api, row.url);
    setMediaUrl(url);
  };

  const stop = () => {
    setMediaUrl("");
    setPlayingItemId("");
  };

  useEffect(() => {
    const data = callRecordingService.allCallRecordings.map((cr) => {
      const media = mediaService.getMediaById(cr.mediaId || "");
      return {
        id: cr.entityId || "",
        at: cr.at,
        formattedDate: getFormattedTimeFromTimestamp(cr.at),
        from: formatPhoneNumber(
          cr.direction === Direction.in ? cr.contactNumber : cr.myNumber
        ),
        to: formatPhoneNumber(
          cr.direction === Direction.in ? cr.myNumber : cr.contactNumber
        ),
        direction:
          cr.direction === Direction.in
            ? "Inbound"
            : cr.direction === Direction.out
            ? "Outbound"
            : "",
        duration: media?.duration || 0,
        url: media?.url || "",
      } as TableRow;
    });
    console.log(callRecordingService.allCallRecordings, data);
    setTableData(data);
  }, [
    callRecordingService.allCallRecordings,
    callRecordingService.updates,
    mediaService,
  ]);

  const clearFilters = () => {
    setFilters(getInitialFilters());
  };

  return (
    <div className="call-recording-table-page auto-flex">
      {loading && <Loader />}
      <div className="data-table-placeholder" ref={dataTablePlaceholderRef}>
        <DataTable
          value={tableData}
          scrollable
          scrollHeight={`${
            dataTablePlaceholderRef.current?.clientHeight || 0
          }px`}
          filters={filters}
          filterDisplay="row"
          emptyMessage="No call recordings found"
        >
          <Column header="Date" field="formattedDate" filter />
          <Column header="From" field="from" filter />
          <Column header="To" field="to" filter />
          <Column header="Direction" field="direction" filter />
          <Column
            header="Duration"
            field="duration"
            body={(row) => formatDuration(row.duration)}
            dataType="numeric"
            filter
            filterPlaceholder="seconds"
          />
          <Column
            header="Play"
            body={(row) =>
              playingItemId === row.id ? (
                !mediaUrl ? (
                  <Button rounded icon="pi pi-spin pi-spinner" />
                ) : (
                  <Button rounded icon="pi pi-stop" onClick={stop} />
                )
              ) : (
                <Button rounded icon="pi pi-play" onClick={play(row)} />
              )
            }
          />
          <Column
            header="Download"
            body={(row) => (
              <Button
                rounded
                icon="pi pi-download"
                onClick={() =>
                  urlFromApiUrl(api, row.url).then((url) => downloadFile(url))
                }
              ></Button>
            )}
          />
        </DataTable>
      </div>
      {mediaUrl && (
        <div className="media-player">
          <audio src={mediaUrl} autoPlay onEnded={stop} />
        </div>
      )}
    </div>
  );
};
