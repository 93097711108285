import { getTextDuration } from "helpers/date";
import { DateTime, Id, PhoneNumber } from "./common";
import { Feature } from "./MyNumber";

export enum Direction {
  in = "inbound",
  out = "outbound",
}

export enum MessageType {
  call = "Call",
  sms = "SMS",
  mms = "MMS",
  voicemail = "Voicemail",
  fax = "Fax",
  callRecording = "Call Recording",
}

export const allMessageTypes = [
  MessageType.call,
  MessageType.sms,
  MessageType.mms,
  MessageType.voicemail,
  MessageType.fax,
  MessageType.callRecording,
];

export enum CallState {
  connecting = "connecting", // only for outgoing
  ringing = "ringing",
  ongoing = "ongoing", // answered
  ended = "ended", // successfully
  cancelled = "cancelled", // ended during connecting
  noAnswer = "noAnswer", // ended during outgoing ringing
  rejected = "rejected",
  missed = "missed",
  failed = "failed", // unknown reason
}

export enum DeviceType {
  mobile = "mobile",
  web = "web",
}

// key is { type, entityId }
export interface Message {
  myNumber: PhoneNumber;
  contactNumber: PhoneNumber;
  direction: Direction;
  type: MessageType;
  entityId?: Id;
  at: DateTime; // for call it means "ended at"
  sentAt?: DateTime; // for call it means "initiated at"
  sending?: boolean;
  failed?: string | boolean;
  // viewedAt?: DateTime;
  body?: string; // text of the message
  // launchedAt?: DateTime;
  mediaId?: Id; // for mms, voicemail
  callStartedAt?: DateTime; // required for call after ringing; for ongoing, cancelled, rejected calls - it equals to `at` (that is actually "ended at")
  callState?: CallState; // required for call;
  deviceType?: DeviceType;
}

export const messageTypeRequiredFeatures: Record<MessageType, Feature[]> = {
  [MessageType.call]: [Feature.calls],
  [MessageType.sms]: [Feature.sms],
  [MessageType.mms]: [Feature.mms],
  [MessageType.voicemail]: [Feature.voicemails],
  [MessageType.fax]: [Feature.fax],
  [MessageType.callRecording]: [Feature.callRecording],
};

export const getMessageDescription = (message: Message): string => {
  switch (message.type) {
    case MessageType.call: {
      let description = "";
      const callDuration = message.at - (message.callStartedAt || message.at);
      const callDurationSuffix = callDuration
        ? ` (${getTextDuration(callDuration)})`
        : "";
      const ringingDuration =
        (message.callStartedAt || message.at) - message.sentAt!;
      const ringingDurationSuffix = ringingDuration
        ? ` (${getTextDuration(ringingDuration)})`
        : "";
      const dir = message.direction === Direction.in ? "Incoming" : "Outgoing";
      const deviceSuffix = ` on ${message.deviceType || "web"}`;
      switch (message.callState) {
        case CallState.connecting:
          description = `Connecting${deviceSuffix}...`;
          break;
        case CallState.ringing:
          description = `Calling${deviceSuffix}...`;
          break;
        case CallState.cancelled:
          description = `Cancelled Call${deviceSuffix}`;
          break;
        case CallState.noAnswer:
          description = `No Asnwer${deviceSuffix} ${ringingDurationSuffix}`;
          break;
        case CallState.rejected:
          description = `Rejected Call${deviceSuffix}`;
          break;
        case CallState.missed:
          description = `Missed Call${deviceSuffix} ${ringingDurationSuffix}`;
          break;
        case CallState.failed:
          description = `Failed Call${deviceSuffix}`;
          break;
        default:
          description = `${dir} Call${deviceSuffix} ${callDurationSuffix}`;
      }
      return description;
    }
    case MessageType.callRecording:
      return "Call recording";
    case MessageType.sms:
      return message.body || "";
    case MessageType.mms:
      return message.body || "MMS message";
    case MessageType.voicemail:
      return "Voicemail";
    case MessageType.fax:
      return "Fax";
    default:
      return "";
  }
};
