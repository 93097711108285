import React from "react";
import { useBrandService } from "hooks/services/useBrandService";
import { useLogout } from "hooks/useLogout";
import "./Header.sass";
import { useAuthService } from "hooks/services/useAuthService";

export const Header = () => {
  const authService = useAuthService();
  const brandService = useBrandService();

  const { brand, loading } = brandService;

  const logout = useLogout();

  return (
    <div className="header-component">
      {!authService.isAutoLoggedIn && (
        <header>
          {!loading && brand.logoUrl && (
            <img className="brand-logo" src={brand.logoUrl} alt={brand.title} />
          )}
          <h1 className="brand-title">
            {loading
              ? ""
              : authService.callRecording
              ? brand.callRecordingTitle
              : authService.callForwarding
              ? brand.callForwardingTitle
              : brand.title}
          </h1>
          {authService.authenticated && (
            <button className="button logout-btn" onClick={logout}>
              Logout
            </button>
          )}
        </header>
      )}
      {authService.isAutoLoggedIn && <h2>Apeiron Communicator – Web</h2>}
    </div>
  );
};
