import { AuthService, useAuthService } from './useAuthService';
import { Template } from 'models/Template';
import { useService, Service, UpdateDispatcher } from './useService';


const ud: UpdateDispatcher = new Set();

export function useTemplateService(): TemplatesService {
  const auth = useAuthService();
  return useService(ud, TemplatesService, { auth });
}

export class TemplatesService extends Service {
  public static serviceName = "TemplatesService";

  public templates: Template[] = [];

  private readonly auth: AuthService;

  constructor({ auth }: { auth: AuthService }) {
    super({ auth });
    this.auth = auth;
  }

  public update() {
    this.auth.account.customData.templates = this.templates;
    this.auth.saveCustomData(); // no await here!
    super.update();
  }

  public async init(): Promise<void> {
    if (this.auth.authenticated) {
      this.templates = this.auth.account.customData.templates;
    }
    this.auth.onLoginHandlers.push(() => {
      this.templates = this.auth.account.customData.templates;
    });
    this.auth.onLogoutHandlers.push(() => {
      this.templates = [];
    });
  }
}
