import React, { useEffect, useRef, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { Loader } from "components/Loader/Loader";
import { useCallService } from "hooks/services/useCallService";
import { useChatService } from "hooks/services/useChatService";
import { useContactService } from "hooks/services/useContactService";
import { useNavigationService } from "hooks/services/useNavigationService";
import { useVoicemailService } from "hooks/services/useVoicemailService";
import { Message, MessageType } from "models/Message";
import { Page, messageTypesByPage } from "models/navigation";
import { ChatMessage } from "./ChatMessage";
import { ComposeSms } from "pages/chats/Compose/ComposeSms";
import { usePrevious } from "hooks/usePrevious";
import { useFaxService } from "hooks/services/useFaxService";
import { useCallRecordingService } from "hooks/services/useCallRecordingService";
import { NewContactArea } from "../NewContactArea/NewContactArea";
import "./Messages.scss";
import { useAuthService } from "hooks/services/useAuthService";

export const Messages = () => {
  const { page } = useNavigationService();
  const { current: contact } = useContactService();
  const authService = useAuthService();
  const chatService = useChatService();
  const callService = useCallService();
  const callRecordingService = useCallRecordingService();
  const voicemailService = useVoicemailService();
  const faxService = useFaxService();

  const [messages, setMessages] = useState<Message[]>([]);
  const [loading, setLoading] = useState(false);
  const [newContactNumber, setNewContactNumber] = useState("");
  const [isInitial, setIsInitial] = useState(true);

  const prevContactId = usePrevious(contact?.id);
  const prevPage = usePrevious(page);
  const softLock = useRef<number>(0);
  const loadedMessagesCount = chatService.loadedCount;
  const prevLoadedMessagesCount = usePrevious(loadedMessagesCount);

  useEffect(() => {
    if (
      contact?.id === prevContactId &&
      page === prevPage &&
      (loadedMessagesCount === prevLoadedMessagesCount || loading) &&
      !isInitial
    ) {
      return;
    }
    softLock.current++;
    (async () => {
      const contactId = contact?.id;
      const currentVersion = softLock.current;
      if (!contactId) {
        return;
      }
      if (loading && contact.id === prevContactId) {
        return;
      }
      switch (page) {
        case Page.callHistory:
          setMessages(callService.getMessagesByContactId(contactId) || []);
          break;
        case Page.callRecordings:
          setMessages(
            callRecordingService.getMessagesByContactId(contactId) || []
          );
          break;
        case Page.voicemails:
          setMessages(voicemailService.getMessagesByContactId(contactId) || []);
          break;
        case Page.fax:
          setMessages(faxService.getMessagesByContactId(contactId) || []);
          break;
        default: {
          setLoading(true);
          setMessages([]);
          let messages = await chatService.getMessagesByContactId(contactId);
          if (page === Page.sms) {
            messages = messages.filter((m) =>
              [MessageType.sms, MessageType.mms].includes(m.type)
            );
          }
          if (currentVersion === softLock.current) {
            setMessages(messages || []);
            setLoading(false);
          }
          break;
        }
      }
    })();
  }, [
    contact,
    page,
    prevContactId,
    prevPage,
    chatService,
    chatService.updates,
    callService,
    voicemailService,
    faxService,
    loading,
    isInitial,
    callRecordingService,
    loadedMessagesCount,
    prevLoadedMessagesCount,
  ]);

  useEffect(() => {
    if (isInitial) {
      setIsInitial(false);
    }
  }, [isInitial, setIsInitial]);

  const [unreadCount, setUnreadCount] = useState(0);
  useEffect(() => {
    if (contact && page) {
      chatService
        .getUnreadCount({
          contactId: contact.id,
          messageTypes: messageTypesByPage(page),
        })
        .then(setUnreadCount);
    }
  }, [contact, chatService, chatService.updates, page]);

  useEffect(() => {
    const contactId = contact?.id;
    if (unreadCount > 0 && contactId && page) {
      setUnreadCount(0);
      const readAt = authService.account.customData.readAt;
      const now = new Date().getTime();
      for (const messageType of messageTypesByPage(page)) {
        readAt[[contactId, messageType].join(":")] = now;
      }
      authService.saveCustomData();
    }
  }, [authService, contact?.id, page, unreadCount]);

  const hasNewContact = page && [Page.chats, Page.sms, Page.fax].includes(page);

  return (
    <div className="messages-component auto-flex">
      {contact ? (
        <div className="messages auto-flex">
          <InfiniteScroll
            dataLength={messages.length}
            next={chatService.loadMore}
            hasMore={chatService.hasMore}
            loader={<Loader />}
            style={{ display: "flex", flexDirection: "column-reverse" }}
          >
            {messages.map((msg, i) => (
              <ChatMessage
                key={`${msg.type}${i}#${msg.entityId}`}
                message={msg}
              />
            ))}
          </InfiniteScroll>
        </div>
      ) : hasNewContact ? (
        <NewContactArea
          newContactNumber={newContactNumber}
          setNewContactNumber={setNewContactNumber}
        />
      ) : (
        <em className="no-contact-selected">
          Please select a contact from the list.
        </em>
      )}
      {hasNewContact ? <ComposeSms newPhoneNumber={newContactNumber} /> : null}
    </div>
  );
};
