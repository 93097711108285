import { environment as defaultEnvironment } from './default';

export const environment = {
  ...defaultEnvironment,
  production: true,
  defaultBrand: {
    ...defaultEnvironment.defaultBrand,
    sip: {
      ...defaultEnvironment.defaultBrand.sip,
      server: 'edge.sip.onsip.com',
      port: 80,
    }  
  },
};
