import { ApiService, useApiService } from "./useApiService";
import { storageKeys } from "const/storage-keys";
import { getStorageItem, setStorageItem } from "helpers/storage";
import { urls } from "const/urls";
import {
  AutoResponder,
  defaultAutoResponder,
  StopWordMatchType,
} from "models/AutoResponder";
import { MyNumberService, useMyNumberService } from "./useMyNumberService";
import { Feature } from "models/MyNumber";
import { TemplatesService, useTemplateService } from "./useTemplatesService";
import { generateGuid } from "helpers/guid";
import { Template } from "models/Template";
import { IdNumeric } from "models/common";
import { useService, Service, UpdateDispatcher } from "./useService";
import { AuthService, useAuthService } from "./useAuthService";

const ud: UpdateDispatcher = new Set();

export function useSmsAutoResponderService(): SmsAutoResponderService {
  const api = useApiService();
  const auth = useAuthService();
  const myNumberService = useMyNumberService();
  const templateService = useTemplateService();
  return useService(ud, SmsAutoResponderService, {
    api,
    auth,
    myNumberService,
    templateService,
  });
}

export class SmsAutoResponderService extends Service {
  public static serviceName = "SmsAutoResponderService";

  private _autoResponders: AutoResponder[] =
    getStorageItem<AutoResponder[]>(storageKeys.sms.autoResponders) || [];
  private _autoRespondersUpdated = false;
  private autoRespondersLoadingCount = 0;
  private loadAutoRespondersPromise: Promise<void> | null = null;

  private readonly api: ApiService;
  private readonly auth: AuthService;
  private readonly myNumberService: MyNumberService;
  private readonly templateService: TemplatesService;

  constructor({
    api,
    auth,
    myNumberService,
    templateService,
  }: {
    api: ApiService;
    auth: AuthService;
    myNumberService: MyNumberService;
    templateService: TemplatesService;
  }) {
    super({ api, auth, myNumberService, templateService });
    this.api = api;
    this.auth = auth;
    this.myNumberService = myNumberService;
    this.templateService = templateService;
  }

  public update() {
    setStorageItem(storageKeys.sms.autoResponders, this._autoResponders);
    super.update();
  }

  public async init(): Promise<void> {
    if (
      this.auth.authenticated &&
      !this.auth.callRecording &&
      !this.auth.callForwarding
    ) {
      this.loadAutoResponders();
    }
    this.auth.onLoginHandlers.push(this.loadAutoResponders.bind(this));
    this.auth.onLogoutHandlers.push(() => {
      this._autoResponders = [];
      this._autoRespondersUpdated = false;
      this.loadAutoRespondersPromise = null;
      this.autoRespondersLoadingCount = 0;
    });
  }

  public async loadAutoResponders(force = false): Promise<void> {
    if (this.auth.callRecording || this.auth.callForwarding) {
      return;
    }
    if (force) {
      this.loadAutoRespondersPromise = null;
    }
    if (!this.loadAutoRespondersPromise) {
      this.loadAutoRespondersPromise = this._loadAutoResponders();
    }
    return this.loadAutoRespondersPromise;
  }

  private async _loadAutoResponders(): Promise<void> {
    this.autoRespondersLoadingCount++;
    this.update();
    let newTemplatesAdded = false;
    try {
      const autoResponders: AutoResponder[] = [];
      interface AutoRespondersResponse {
        ar_id: number;
        active: boolean;
        number: string;
        stopword_match_type: "exact" | "simple" | "regex" | "any";
        stopword: string;
        response: string;
      }
      interface AutoRespondersByPageResponse {
        count: number;
        next?: string;
        results: AutoRespondersResponse[];
      }
      await this.templateService.ready;
      await this.myNumberService.ready;
      const mySmsNunmbers = this.myNumberService.myNumbers
        .filter((n) => n.features.includes(Feature.sms))
        .map((n) => n.number);
      await Promise.all(
        mySmsNunmbers.map(async (myNumber) => {
          const firstPageUrl = urls.autoResponders.replace(
            ":myNumber",
            myNumber
          );
          let url = firstPageUrl;
          let autoResponderResponses: AutoRespondersResponse[] = [];
          do {
            const response = await this.api.get<AutoRespondersByPageResponse>(
              url
            );
            if (url === firstPageUrl) {
              // page 1
              if (
                response.count ===
                getStorageItem<number>(storageKeys.sms.autoResponderCount)
              ) {
                autoResponderResponses =
                  getStorageItem<AutoRespondersResponse[]>(
                    storageKeys.sms.autoResponders
                  ) || [];
                break;
              }
            }
            autoResponderResponses.push(...response.results);
            url = response.next || "";
          } while (url);
          const newAutoResponders = autoResponderResponses.map(
            (x) =>
              ({
                id: x.ar_id,
                myNumber: x.number,
                body: x.response,
                disabled: !x.active,
                stopWordMatchType: x.stopword_match_type as StopWordMatchType,
                stopWord: x.stopword,
              } as AutoResponder)
          );
          autoResponders.push(...newAutoResponders);
          const newTemplates = newAutoResponders
            .filter(
              (ar) =>
                !ar.disabled &&
                ar.stopWordMatchType === StopWordMatchType.regex &&
                ar.stopWord === ".*" &&
                this.templateService.templates.every((t) => t.body !== ar.body)
            )
            .map((ar, i) => ({
              id: generateGuid(),
              name: `Autoresponder for ${myNumber}${i > 0 ? `#${i + 1}` : ""}`,
              body: ar.body,
            }));
          if (newTemplates.length > 0) {
            this.templateService.templates.push(...newTemplates);
            newTemplatesAdded = true;
          }
        })
      );
      this._autoResponders = autoResponders;
      this._autoRespondersUpdated = true;
    } finally {
      this.autoRespondersLoadingCount--;
      if (newTemplatesAdded) {
        this.templateService.update();
      }
      this.update();
    }
  }

  public get autoRespondersLoading(): boolean {
    return this.autoRespondersLoadingCount > 0;
  }

  public get autoResponders(): AutoResponder[] {
    return this._autoResponders;
  }

  public getTemplate(myNumber: string): Template | undefined {
    const autoResponder = this.autoResponders.find(
      (ar) =>
        ar.myNumber === myNumber &&
        !ar.disabled &&
        ar.stopWordMatchType === StopWordMatchType.regex &&
        ar.stopWord === ".*"
    );
    if (!autoResponder) {
      return undefined;
    }
    return this.templateService.templates.find(
      (t) => t.body === autoResponder.body
    );
  }

  public async setAutoResponder(myNumber: string, message: string) {
    this.autoRespondersLoadingCount++;
    this.update();
    try {
      let autoResponder = this.autoResponders.find(
        (ar) =>
          ar.myNumber === myNumber &&
          ar.stopWordMatchType === StopWordMatchType.regex &&
          ar.stopWord === ".*"
      );
      message = (message || "").trim();
      if (!autoResponder && !message) {
        return;
      }
      if (!autoResponder) {
        autoResponder = { ...defaultAutoResponder, myNumber };
        this.autoResponders.push(autoResponder);
      }
      autoResponder.body = message;
      autoResponder.disabled = !message;
      this.update();
      const payload = {
        stopword_match_type: autoResponder.stopWordMatchType,
        stopword: autoResponder.stopWord,
        response: autoResponder.body,
        active: !autoResponder.disabled,
      };
      if (!autoResponder.id) {
        const { ar_id: id } = await this.api.post<{ ar_id: IdNumeric }>(
          urls.createAutoResponder.replace(":myNumber", myNumber),
          payload
        );
        autoResponder.id = id;
      } else {
        await this.api.post(
          urls.updateAutoResponder
            .replace(":myNumber", myNumber)
            .replace(":id", autoResponder.id.toString()),
          payload
        );
      }
    } finally {
      this.autoRespondersLoadingCount--;
      this.update();
    }
  }
}
