import { useService, Service, UpdateDispatcher } from './useService';

const ud: UpdateDispatcher = new Set();

export function useMapService(): MapService {
  return useService(ud, MapService);
}

export class MapService extends Service {
  public static serviceName = "MapService";

  public selectedLocation: { latitude: number; longitude: number, label: string } | undefined = undefined;
}
