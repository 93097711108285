import { environment } from "environments";

const prefix = environment.storagePrefix;

export const storageKeys = {
  auth: {
    token: "token",
    customerNumber: `${prefix}customerNumber`,
  },
  myNumbers: `${prefix}my-numbers`,
  contacts: `${prefix}contacts`,
  media: `${prefix}media`,
  documentCount: `${prefix}account-document-count`,
  calls: {
    messages: `${prefix}call-messages`,
    dialingNumber: `${prefix}call-dial-number`,
    recordingNumbers: `${prefix}call-recordings-numbers`,
    recordings: `${prefix}call-recordings`,
    recordingTotalCount: `${prefix}call-recording-count`,
    recordingNextUrls: `${prefix}call-recording-next-urls`,
  },
  sms: {
    threads: `${prefix}sms-threads`,
    messages: `${prefix}sms-messages`,
    smsLists: `${prefix}sms-lists`,
    smsCampaigns: `${prefix}sms-campaigns`,
    massMessages: `${prefix}mass-messages`,
    autoResponders: `${prefix}sms-auto-responders`,
    autoResponderCount: `${prefix}sms-auto-responder-count`,
  },
  mms: {
    messages: `${prefix}mms-messages`,
    countIn: `${prefix}mms-count-in/:myNumber`,
    countOut: `${prefix}mms-count-out/:myNumber`,
  },
  fax: {
    messages: `${prefix}fax-messages`,
    count: `${prefix}fax-count/:myNumber`,
  },
  voicemails: {
    messages: `${prefix}voicemail-messages`,
    count: `${prefix}voicemail-count`,
    greetingResponses: `${prefix}voicemail-greeting-responses`,
    greetingCount: `${prefix}voicemail-greeting-count`,
  },
  navigation: {
    mode: `${prefix}nav-mode`,
    page: `${prefix}nav-page`,
  },
  layout: {
    contactsSplitter: `${prefix}layout-contacts-splitter`,
    smsListSplitter: `${prefix}sms-list-splitter`,
  },
};
