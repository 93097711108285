import { useEffect, useRef } from "react";
import { useLocation } from "react-router";
import { Contacts } from "components/Contacts/Contacts";
import { useNavigationService } from "hooks/services/useNavigationService";
import { AppMode, Page, pageInfo } from "models/navigation";
import { MainMenu } from "../MainMenu/MainMenu";
import { PageTabs } from "../PageTabs/PageTabs";
import { Splitter, SplitterPanel } from "primereact/splitter";
import { storageKeys } from "const/storage-keys";
import { useMyNumberService } from "hooks/services/useMyNumberService";
import { Feature } from "models/MyNumber";
import { useChatService } from "hooks/services/useChatService";
import { useCallService } from "hooks/services/useCallService";
import { Header } from "components/Header/Header";
import { useContactService } from "hooks/services/useContactService";
import { useAuthService } from "hooks/services/useAuthService";
import { Loader } from "components/Loader/Loader";
import "./AppLayout.scss";

export const AppLayout = ({
  mode,
  page: initialPage,
  children,
}: {
  mode: AppMode;
  page?: Page;
  children: any;
}) => {
  const myNumberService = useMyNumberService();
  const contactService = useContactService();
  // initialize all services (if not initialized yet)
  useChatService(); // includes myNumberService, contactService, callService, smsService, voicemailService
  // useSmsListService(); // let's load it only when user visits SmsList page

  const location = useLocation();
  const navigation = useNavigationService();

  const remoteAudioRef = useRef<HTMLAudioElement>(null);

  useCallService({ remoteAudioRef });

  useEffect(() => {
    navigation.mode = mode;
    if (initialPage) {
      navigation.page = initialPage;
    }
  }, [navigation, mode, initialPage]);

  useEffect(() => {
    if (myNumberService.isReady && myNumberService.myNumbers.length > 0) {
      switch (mode) {
        case AppMode.calls: {
          if (
            !myNumberService.current?.features.includes(Feature.calls) &&
            myNumberService.hasFeature(Feature.calls)
          ) {
            myNumberService.setCurrent(
              myNumberService.myNumbers.find((n) =>
                n.features.includes(Feature.calls)
              )!.number
            );
          }
          break;
        }
        case AppMode.sms: {
          if (
            !myNumberService.current?.features.includes(Feature.sms) &&
            myNumberService.hasFeature(Feature.sms)
          ) {
            myNumberService.setCurrent(
              myNumberService.myNumbers.find((n) =>
                n.features.includes(Feature.sms)
              )!.number
            );
          }
          break;
        }
        case AppMode.chats: {
          if (
            !myNumberService.current?.features.some((f) =>
              [Feature.calls, Feature.sms].includes(f)
            ) &&
            (myNumberService.hasFeature(Feature.calls) ||
              myNumberService.hasFeature(Feature.sms))
          ) {
            myNumberService.setCurrent(
              myNumberService.myNumbers.find((n) =>
                n.features.some((f) => [Feature.calls, Feature.sms].includes(f))
              )!.number
            );
          }
          break;
        }
      }
    }
  }, [mode, myNumberService, myNumberService.isReady]);

  const { page } = navigation;
  const withContacts =
    page &&
    [
      Page.chats,
      Page.callHistory,
      Page.callRecordings,
      Page.sms,
      Page.voicemails,
      Page.gallery,
      Page.fax,
    ].includes(page);
  const withContent =
    !withContacts ||
    (page && contactService.getContactsForPage(page).length > 0);

  const isNavigating =
    !navigation.page || location.pathname !== pageInfo[navigation.page].route;

  if (!myNumberService.isReady) {
    return <Loader />;
  }

  return (
    <div className="app-layout-component">
      <audio autoPlay ref={remoteAudioRef} />
      <MainMenu />
      <PageTabs />
      <Header />
      <div className="page-content auto-flex">
        {isNavigating ? null : withContacts && withContent ? (
          <Splitter
            stateKey={storageKeys.layout.contactsSplitter}
            stateStorage="local"
          >
            <SplitterPanel size={20} className="auto-flex">
              <Contacts />
            </SplitterPanel>
            <SplitterPanel className="auto-flex">
              <div className="auto-flex">{children}</div>
            </SplitterPanel>
          </Splitter>
        ) : withContacts ? (
          <Contacts />
        ) : withContent ? (
          <div className="auto-flex">{children}</div>
        ) : null}
      </div>
    </div>
  );
};
