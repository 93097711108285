import { useCallback, useEffect, useState } from 'react';

// const [startRecording, stopRecording, recordedFile] = useRecording();
const useRecording = (): [() => void, () => void, File | undefined] => {
  const [mediaRecorder, setMediaRecorder] = useState<MediaRecorder | null>(null);
  const [chunks, setChunks] = useState<Blob[]>([]);
  const [file, setFile] = useState<File | undefined>(undefined);

  const startRecording = useCallback(() => {
    setFile(undefined);
    setChunks([]);
    if (!navigator.mediaDevices.getUserMedia) {
      console.error('getUserMedia is not supported in this browser');
      return;
    }
    navigator.mediaDevices.getUserMedia({ audio: true })
      .then((stream: MediaStream) => {
        const recorder = new MediaRecorder(stream);
        
        recorder.ondataavailable = (event: BlobEvent) => {
          console.log('ondataavailable', event.data);
          setChunks((prevChunks) => [...prevChunks, event.data]);
        };
        
        setMediaRecorder(recorder);
        recorder.start();
      })
      .catch((error: any) => {
        console.error('Error accessing the microphone:', error);
      });
  }, []);

  const stopRecording = useCallback(async () => {
    if (mediaRecorder && mediaRecorder.state !== 'inactive') {
      mediaRecorder.stop();
    }
  }, [mediaRecorder]);

  useEffect(() => {
    console.log('mediaRecorder state', mediaRecorder?.state, chunks.length);
    if ((!mediaRecorder || mediaRecorder.state !== 'inactive') || chunks.length === 0) {
      return;
    }
    setTimeout(() => {
      const type = chunks[0].type;
      const blob = new Blob(chunks, { type });
      const fileName = 'recording_' + new Date().toISOString() + '.wav';
      setFile(new File([blob], fileName, { type }));
  
      setChunks([]);
      setMediaRecorder(null);
    }, 100);
  }, [mediaRecorder, chunks]);

  useEffect(() => {
    return () => {
      stopRecording();
    };
  }, [stopRecording]);

  return [startRecording, stopRecording, file];
};

export default useRecording;
