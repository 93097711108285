import { UA } from "jssip";
import { PhoneNumber } from "./common";

export enum Feature {
  calls = "calls",
  sms = "sms",
  mms = "mms",
  voicemails = "voicemails",
  fax = "fax",
  callForwarding = "call_forwarding",
  callRecording = "call_recording",
  callProcedures = "call_proc",
}

export const features = [
  Feature.calls,
  Feature.sms,
  Feature.mms,
  Feature.voicemails,
  Feature.fax,
  Feature.callForwarding,
  Feature.callRecording,
  Feature.callProcedures,
];

// key is number
export interface MyNumber {
  features: Feature[];
  number: PhoneNumber;
  name: string; // = formatPhoneNumber(number)
  calls?: {
    // exists when features.includes(Feature.calls)
    ready: boolean; // true when SIP is initialized for calls
    sipPassword: string;
    sipAgent?: UA;
  };
  sms?: {
    // exists when features.includes(Feature.sms)
    ready: boolean; // true when web socket is initialized for messaging
  };
  mms?: {
    // exists when features.includes(Feature.mms)
    ready: boolean; // true when web socket is initialized for messaging
  };
  fax?: {
    // exists when features.includes(Feature.fax)
    ready: boolean; // true when web socket is initialized for messaging
  };
}
