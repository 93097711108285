import { environment as defaultEnvironment } from "./default";

export const environment = {
  ...defaultEnvironment,
  production: true,
  baseUrl:
    window.location.hostname === "dashboard.apeiron.io"
      ? "/dashboard/communicator/"
      : "/",
  map: {
    url: "http://communicator.apeiron.io/mapping/",
    secret: "XbhjxiqgGavJaDPY43er",
  },
};
