import React, { useCallback, useEffect, useState } from "react";
import { useContactService } from "hooks/services/useContactService";
import { LastMessageDescription } from "./LastMessage";
import { getDateOrTimeText } from "helpers/date";
import {
  Contact,
  contactHasCustomName,
  getContactDefaultName,
  getContactDisplayName,
  getContactDisplayNameWithNumbers,
} from "models/Contact";
import { FaUserEdit } from "react-icons/fa";
import { Message } from "models/Message";
import { InputText } from "primereact/inputtext";
import { usePrevious } from "hooks/usePrevious";
import { AiFillPushpin, AiOutlinePushpin } from "react-icons/ai";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { FloatLabel } from "primereact/floatlabel";
import { useFocus } from "hooks/useFocus";
import "./ContactCard.scss";

export const ContactCard = ({
  contact,
  lastMessage,
  time,
  unreadCount,
  selectable = true,
}: {
  contact: Contact;
  lastMessage?: Message;
  time?: string;
  unreadCount?: number;
  selectable?: boolean;
}) => {
  const contactService = useContactService();

  const [editMode, setEditMode] = useState(false);

  const [contactName, setContactName] = useState(
    contactHasCustomName(contact) ? contact.name : ""
  );

  const contactNumbers = getContactDefaultName(contact);

  const selectContact = useCallback(
    (contact: Contact) => (e: React.MouseEvent<HTMLElement>) => {
      e.stopPropagation();
      contactService.current = contact;
      contactService.update();
    },
    [contactService]
  );

  const prevContactId = usePrevious(contact.id);
  useEffect(() => {
    if (contact.id === prevContactId) {
      return;
    }
    setContactName(getContactDisplayName(contact));
  }, [contact, prevContactId]);

  const submit = () => {
    contact.name = (contactName || "").trim();
    contactService.update();
    closeEditDialog();
  };

  const deleteContact = () => {
    closeEditDialog();
    contactService.deleteContact(contact);
  };

  const togglePin = () => {
    contact.pinned = !contact.pinned;
    contactService.update();
  };

  const selected = selectable && contactService.current?.id === contact.id;

  const edit = () => {
    setContactName(contactHasCustomName(contact) ? contact.name : "");
    setEditMode(true);
  };

  const closeEditDialog = () => {
    setEditMode(false);
  };

  return (
    <div
      className={`
        contact-card-component
        ${selected && "selected"}
      `}
      onClick={selectable ? selectContact(contact) : undefined}
    >
      <div className="name">
        <span title={getContactDisplayNameWithNumbers(contact)}>
          {getContactDisplayName(contact)}
        </span>
      </div>
      <div className="last-message">
        {lastMessage ? (
          <LastMessageDescription lastMessaage={lastMessage} />
        ) : null}
      </div>
      <div className="date-time">
        {time !== undefined
          ? time
          : lastMessage
          ? getDateOrTimeText(lastMessage.at)
          : null}
      </div>
      <div className="manage-contact">
        {(selected || contact.pinned) && (
          <div
            className={`contact-pin ${contact.pinned && "pinned"}`}
            onClick={() => togglePin()}
          >
            {contact.pinned ? <AiFillPushpin /> : <AiOutlinePushpin />}
          </div>
        )}
        {selected && <FaUserEdit className="edit-user" onClick={edit} />}
        {!selected && unreadCount ? (
          <div className="unread-count">{unreadCount}</div>
        ) : null}
      </div>
      <Dialog
        header={contactName || getContactDisplayName(contact)}
        footer={
          <>
            <Button onClick={submit}>OK</Button>
            <Button severity="secondary" onClick={closeEditDialog}>
              Cancel
            </Button>
          </>
        }
        visible={editMode}
        style={{ minWidth: "300px" }}
        onHide={closeEditDialog}
      >
        <div className="form-input-group">
          <FloatLabel>
            <label>Phone Number{contact.numbers.length > 1 ? "s" : ""}</label>
            <InputText value={contactNumbers} readOnly />
          </FloatLabel>
        </div>
        <div className="form-input-group">
          <FloatLabel>
            <label>Contact Name</label>
            <InputText
              value={contactName}
              onChange={(e) => setContactName(e.target.value)}
            />
          </FloatLabel>
        </div>
        {!lastMessage && (
          <div className="form-input-group">
            <Button severity="danger" onClick={deleteContact}>
              Delete Contact
            </Button>
          </div>
        )}
      </Dialog>
    </div>
  );
};
