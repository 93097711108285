import { ApiService, useApiService } from "./useApiService";
import { urls } from "const/urls";
import { MyNumberService, useMyNumberService } from "./useMyNumberService";
import { Feature, MyNumber } from "models/MyNumber";
import { Id } from "models/common";
import { CallProcedure } from "models/CallProcedure";
import { useService, Service, UpdateDispatcher } from "./useService";

const ud: UpdateDispatcher = new Set();

export function useCallProceduresService(): CallProceduresService {
  const api = useApiService();
  const myNumberService = useMyNumberService();
  return useService(ud, CallProceduresService, { api, myNumberService });
}

export interface MyNumberCallProcedure {
  myNumber: MyNumber;
  callProcedure?: CallProcedure;
}

export class CallProceduresService extends Service {
  public static serviceName = "CallProceduresService";

  private readonly api: ApiService;
  private readonly myNumberService: MyNumberService;

  constructor({
    api,
    myNumberService,
  }: {
    api: ApiService;
    myNumberService: MyNumberService;
  }) {
    super({ api, myNumberService });
    this.api = api;
    this.myNumberService = myNumberService;
  }

  // returns all myNumbers, with or without call procedure
  public async loadMyNumberCallProcedures(): Promise<{
    callProcedures: CallProcedure[];
    myNumberCallProcedures: MyNumberCallProcedure[];
  }> {
    type CallProceduresResponse = Array<{
      id: string;
      label: string;
      endpoint_type: string;
      endpoint_url: string;
      xml: string;
    }>;

    // load data
    const callProcedures: CallProcedure[] = (
      await this.api.get<CallProceduresResponse>(urls.callProcedures)
    ).map((r) => ({
      id: r.id,
      label: r.label,
      endpointType: r.endpoint_type,
      url: r.endpoint_url,
      xml: r.xml,
    }));

    const callProcedureNumbers: string[][] = // number list per each call procedure
      await Promise.all(
        callProcedures.map(async (callProcedure) =>
          this.api.get<string[]>(
            urls.callProcedureNumbers.replace(":endpointId", callProcedure.id)
          )
        )
      );

    const myNumberCallProcedures: MyNumberCallProcedure[] =
      this.myNumberService.myNumbers
        .filter((n) => n.features.includes(Feature.callProcedures))
        .map((myNumber) => ({
          myNumber,
          callProcedure: callProcedures.find((_, index) =>
            callProcedureNumbers[index].find((numbers) =>
              numbers.includes(myNumber.number)
            )
          ),
        }));

    return {
      callProcedures,
      myNumberCallProcedures,
    };
  }

  async addMyNumberCallProcedure({
    myNumber,
    callProcedureId,
  }: {
    myNumber: string;
    callProcedureId: Id;
  }) {
    await this.ready;
    await this.api.put(
      urls.addCallProcedureNumber.replace(":endpointId", callProcedureId),
      {},
      { form: { number: myNumber } }
    );
    this.update();
  }

  async removeMyNumberCallProcedure({
    myNumber,
    callProcedureId,
  }: {
    myNumber: string;
    callProcedureId: Id;
  }) {
    await this.ready;
    await this.api.delete(
      urls.removeCallProcedureNumber
        .replace(":endpointId", callProcedureId)
        .replace(":number", myNumber)
    );
    this.update();
  }
}
